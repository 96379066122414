/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"

import Home from "./pages/Home"
import About from "./pages/About"
import Products from "./pages/Products"
import Services from "./pages/Services"
import Blog from "./pages/Blog"
import BlogDetail from "./pages/BlogDetail"
import Resources from "./pages/Resources"
import Contact from "./pages/Contact"
import Calculator from "./pages/Calculator"
import Supplier from "./pages/products/Supplier"
import Dealer from "./pages/products/Dealer"
import Miner from "./pages/products/Miner"
import Exchange from "./pages/products/Exchange"
import Investor from "./pages/products/Investor"
import Term from "./pages/TermOfUse"
import Privacy from "./pages/Privacy"
import Consent from "./components/layout/Consent"
import MainNavigation from "./components/layout/MainNavigation"

export default function App() {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location && location.pathname) {
            switch (location.pathname) {
                case "/detail/how-the-blockchain-and-tokenization-can-enhance-the-oil-and-gas-sector":
                    navigate(
                        "/blog/how-the-blockchain-and-tokenization-can-enhance-the-oil-and-gas-sector",
                        { replace: true },
                    )
                    break
                case "/detail/why-the-energy-sector-should-embrace-nfts":
                    navigate(
                        "/blog/why-the-energy-sector-should-embrace-nfts",
                        { replace: true },
                    )
                    break
                case "/detail/how-much-money-can-you-make-from-bitcoin-mining":
                    navigate(
                        "/blog/how-much-money-can-you-make-from-bitcoin-mining",
                        { replace: true },
                    )
                    break
                case "/detail/q1-2021-permianchain-outlook-with-mohamed-el-masri-founder-ceo":
                    navigate(
                        "/blog/q1-2021-permianchain-outlook-with-mohamed-el-masri-founder-ceo",
                        { replace: true },
                    )
                    break
                case "/detail/cryptos-quest-for-power-bridging-the-crypto-mining-and-natural-gas-sectors":
                    navigate(
                        "/blog/cryptos-quest-for-power-bridging-the-crypto-mining-and-natural-gas-sectors",
                        { replace: true },
                    )
                    break
                case "/crypto-mining-management":
                    navigate("/services", { replace: true })
                    break
                case "/natural-resource-tokenization":
                    navigate("/services", { replace: true })
                    break
                case "/digital-energy-conservation":
                    navigate("/services", { replace: true })
                    break
                default:
                    break
            }
        }
    }, [location])
    return (
        <>
            <MainNavigation />
            <Consent />
            <Routes>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route path="/about" exact>
                    <About />
                </Route>
                <Route path="/products" exact>
                    <Products />
                </Route>
                <Route path="/services" exact>
                    <Services />
                </Route>
                <Route path="/blog" exact>
                    <Blog />
                </Route>
                <Route path="/blog/:id">
                    <BlogDetail />
                </Route>
                <Route path="/resources" exact>
                    <Resources />
                </Route>
                <Route path="/contact" exact>
                    <Contact />
                </Route>
                <Route path="/mining-calculator" exact>
                    <Calculator />
                </Route>
                <Route path="/products-supplier" exact>
                    <Supplier />
                </Route>
                <Route path="/products-dealer" exact>
                    <Dealer />
                </Route>
                <Route path="/products-miner" exact>
                    <Miner />
                </Route>
                <Route path="/products-exchange" exact>
                    <Exchange />
                </Route>
                <Route path="/products-investor" exact>
                    <Investor />
                </Route>
                <Route path="/terms-of-use" exact>
                    <Term />
                </Route>
                <Route path="/privacy-policy" exact>
                    <Privacy />
                </Route>
            </Routes>
        </>
    )
}
