import React from "react"
import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

export default function AssetTokenization() {
    return (
        <div className="grid xl:grid-cols-3 gap-y-12 bg-services-at bg-no-repeat bg-center bg-cover items-center justify-items-center mt-6 py-24">
            <div className="flex flex-col items-center xl:items-start bg-at-diamond bg-no-repeat bg-left ml-0 xl2:ml-40 px-0 sm:px-36">
                <Heading6
                    text="Our services"
                    classes="text-blue-main text-center sm:text-left uppercase my-4"
                />
                <Heading2
                    text="Asset Tokenization"
                    classes="text-center sm:text-left text-xl sm:text-xl"
                />

                <Paragraph
                    text=""
                    classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left sm:text-sm"
                />
            </div>

            <div className="flex flex-col col-span-2">
                <div className="flex flex-col px-2 sm:px-24 xl2:px-56">
                    <Heading6
                        text="HELPING OIL AND GAS FIRMS TOKENISE THEIR ASSETS"
                        classes="text-blue-main text-center uppercase mt-4 text-md bg-white border border-dashed border-blue-main border-opacity-30 rounded-t-sm py-4 px-24"
                    />
                    <Paragraph
                        text="We are using the blockchain to tokenize confirmed deposits of natural resources so that they can be represented digitally as smart contracts on a blockchain. This means they can be traded quickly, efficiently and legally, without significant brokerage or administrative costs, far earlier than is currently possible."
                        classes="text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-sm px-8 py-16 bg-blue-lightblue bg-opacity-25  border border-t-0 border-dashed border-blue-main border-opacity-30 "
                    />
                </div>

                <div className="flex flex-col px-2 sm:px-24 xl2:px-56">
                    <Paragraph
                        text="We support oil and gas clients through a process that’s similar to traditional securitization, but blockchain-enabled functions allow for instant settlement, reduced holding periods, enhanced transparency, and digital automation."
                        classes="text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-sm px-8 py-6 my-6 bg-blue-lightblue bg-opacity-25  border border-dashed border-blue-main border-opacity-30 "
                    />
                </div>
            </div>
        </div>
    )
}
