import React from "react"

import Banner from "../components/layout/about/Banner"
import CompanyStatement from "../components/layout/about/CompanyStatement"
import JoinUs from "../components/layout/about/JoinUs"
import Team from "../components/layout/about/Team"
import WhatWeDo from "../components/layout/about/WhatWeDo"
import Approach from "../components/layout/about/Approach"
import Overview from "../components/layout/about/Overview"
import WhitePaperOverview from "../components/layout/about/WhitePaperOverview"
import CallToAction from "../components/layout/CallToAction"
import Footer from "../components/layout/Footer"
import { Helmet } from "react-helmet"

function About() {
    return (
        <div className="flex flex-col ">
            <Helmet>
                <title>About - Circular Economy | PermianChain</title>
                <meta
                    name="keywords"
                    content="blockchain natural resources, blockchain for oil companies"
                />
                <meta
                    name="description"
                    property="og:description"
                    content="PermianChain brings together the crypto-mining and oil and gas sector to transform the way that natural gas resources are funded, produced, bought and sold. "
                />
                <meta
                    name="title"
                    property="og:title"
                    content="About - Circular Economy | PermianChain"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/about"
                />
                <link rel="canonical" href="https://permianchain.com/about" />
            </Helmet>
            <Banner />
            <CompanyStatement />
            <Team />
            <JoinUs />
            <WhatWeDo />
            <Approach />
            <Overview />
            <WhitePaperOverview />
            <CallToAction link="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&amp;id=cee50e37f4" />
            <Footer />
        </div>
    )
}

export default About
