import React from "react"

function Button({ text, mode, classes, type, onClick, ...props }) {
    let button =
        "px-4 lg:px-12 py-4 uppercase text-xs lg:text-xs2 font-bold rounded focus:outline-none transition duration-500 ease-in-out transform hover:-translate-y-1 "

    if (mode === "primary") {
        button += "bg-blue-main text-white "
    } else if (mode === "secondary") {
        button += " border border-blue-heavy text-blue-heavy "
    }

    return (
        <button
            disabled={props.buttonDisabled}
            onClick={onClick}
            type={type}
            className={`${button} ${classes}`}
        >
            {text}
        </button>
    )
}

function Link({ text, mode, classes, href, isBlank }) {
    let button =
        "px-4 lg:px-12 py-4 uppercase text-xs lg:text-xs2 font-bold rounded focus:outline-none transition duration-500 ease-in-out transform hover:-translate-y-1 "

    if (mode === "primary") {
        button += "bg-blue-main text-white "
    } else if (mode === "secondary") {
        button += " border border-blue-heavy text-blue-heavy "
    }

    return (
        <a
            href={href}
            className={`${button} ${classes}`}
            target={!!isBlank ? "_blank" : "_self"}
            rel="noreferrer"
        >
            {text}
        </a>
    )
}

export default Button
export { Link }
