import React from "react"

// Elements
import { Heading1 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"
import { Link } from "../../elements/Button"

import AboutIllustration from "../../../images/about-hero-illustration.png"
import SocialIcons from "../../SocialIcons"

function Banner() {
    return (
        <div className=" bg-blue-light xl:px-14 xl2:px-4 lg:pt-28 lg:pb-14">
            <div className="pt-40 lg:pt-20 pb-12 xl2:mx-28 xl:px-12  bg-blue-lightblue xl:rounded-t-xl xl:rounded-b-large border border-blue-borderColor border-opacity-50 bg-opacity-50">
                <div className="flex flex-col space-y-10 justify-between items-center xl:flex-row xl:space-x-2 xl2:space-x-10">
                    <div className="flex flex-col items-center xl:items-start xl:w-2/6 xl2:ml-12 xl2:w-2/6">
                        <Heading1
                            text="About PermianChain"
                            classes="text-xl2 text-center xl:text-left xl2:text-xl3"
                        />
                        <Paragraph
                            text="Established in 2018, PermianChain Technologies Inc. is a blockchain start-up based in Toronto, Canada."
                            classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                        />

                        <Paragraph
                            text="We’ve brought together a global team of specialists from the oil and gas, capital markets and technology sectors to create a permissioned access blockchain platform that will unlock liquidity and significantly enhance the way that natural resource reserves are funded, produced and traded."
                            classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                        />

                        <div className="flex flex-row px-1 mt-5 items-center whitespace-pre-wrap text-xs leading-6 text-center font-semibold xl:text-xs2">
                            <p className="text-blueGray-500 xl:text-left">
                                We are a pioneer member of the{" "}
                                <span className="text-blue-main">
                                    Blockchain Research Institute (BRI)
                                </span>
                                {"\n"}
                                and start-up member of the{" "}
                                <span className="text-blue-main">
                                    Petroleum Technology Alliance Canada (PTAC).
                                </span>
                            </p>
                        </div>

                        <div className="flex space-x-2 mt-10">
                            <Link
                                isBlank
                                text="Case Study"
                                mode="primary"
                                href="/El_Masri_Oil_Natural_Gas_and_Blockchain_Research_Institute.pdf"
                            />
                            <Link
                                isBlank
                                text="White Paper "
                                mode="secondary"
                                classes="bg-blue-extralight"
                                href="/digital-energy-currency-whitepaper.pdf"
                            />
                        </div>
                    </div>

                    <div className="">
                        <img src={AboutIllustration} alt="about"></img>
                    </div>

                    <div className="flex flex-row items-center justify-center space-x-8 xl:flex-col xl:space-x-0 xl:space-y-8 xl:pl-4 xl2:px-20">
                        <SocialIcons color="main" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
