import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"

library.add(fab)
export default function SocialIcons({ color }) {
    return (
        <>
            <a
                href="https://www.instagram.com/permianchainofficial/"
                target="_blank"
                rel="noreferrer"
            >
                <FontAwesomeIcon
                    icon={["fab", "instagram-square"]}
                    size="2x"
                    className={`w-7 h-7 cursor-pointer fill-current text-blue-${color} text-opacity-30 transition duration-500 ease-in-out transform hover:scale-150 hover:text-opacity-100 `}
                />
            </a>

            <a
                href="https://www.youtube.com/channel/UCLz8IV2eDe9i7VsHFAP7jkQ"
                target="_blank"
                rel="noreferrer"
            >
                <FontAwesomeIcon
                    icon={["fab", "youtube-square"]}
                    size="2x"
                    className={`w-7 h-7 cursor-pointer fill-current text-blue-${color} text-opacity-30 transition duration-500 ease-in-out transform hover:scale-150 hover:text-opacity-100 `}
                />
            </a>

            <a
                href="https://t.me/PermianChain"
                target="_blank"
                rel="noreferrer"
            >
                <FontAwesomeIcon
                    icon={["fab", "telegram-plane"]}
                    size="2x"
                    className={`w-7 h-7 cursor-pointer fill-current text-blue-${color} text-opacity-30 transition duration-500 ease-in-out transform hover:scale-150 hover:text-opacity-100 `}
                />
            </a>

            <a
                href="http://www.twitter.com/permianchain"
                target="_blank"
                rel="noreferrer"
            >
                <FontAwesomeIcon
                    icon={["fab", "twitter"]}
                    size="2x"
                    className={`w-7 h-7 cursor-pointer fill-current text-blue-${color} text-opacity-30 transition duration-500 ease-in-out transform hover:scale-150 hover:text-opacity-100 `}
                />
            </a>

            <a
                href="https://www.linkedin.com/company/permianchain/"
                target="_blank"
                rel="noreferrer"
            >
                <FontAwesomeIcon
                    icon={["fab", "linkedin-in"]}
                    size="2x"
                    className={`w-7 h-7 cursor-pointer fill-current text-blue-${color} text-opacity-30 transition duration-500 ease-in-out transform hover:scale-150 hover:text-opacity-100 `}
                />
            </a>
        </>
    )
}
