import React from "react"

// Elements
import { Heading1 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"
import { Link } from "../../elements/Button"

import SocialIcons from "../../SocialIcons"

import SupplierLogo2 from "../../../images/PermianSupplier.png"
import MinerLogo2 from "../../../images/PermianMiner.png"
import DealerLogo2 from "../../../images/PermianDealer.png"
import ExchangeLogo2 from "../../../images/PermianExchange.png"
import InvestorLogo2 from "../../../images/PermianInvestor.png"

function Banner({
    logo,
    bannerHeader,
    bannerText,
    bannerIllustration,
    category,
}) {
    let productLogo
    if (logo === "1") {
        // productLogo = <SupplierLogo className="my-4" />;
        productLogo = SupplierLogo2
    } else if (logo === "2") {
        // productLogo = <DealerLogo />;
        productLogo = DealerLogo2
    } else if (logo === "3") {
        // productLogo = <MinerLogo />;
        productLogo = MinerLogo2
    } else if (logo === "4") {
        // productLogo = <ExchangeLogo />;
        productLogo = ExchangeLogo2
    } else if (logo === "5") {
        // productLogo = <InvestorLogo />;
        productLogo = InvestorLogo2
    }
    return (
        <div className=" bg-blue-light xl:px-14 xl2:px-4 pt-28 lg:pb-14 ">
            <div className="px-8 lg:pt-20 lg:pb-8 xl2:mx-28 xl:px-12 bg-blue-lightblue xl:rounded-t-xl xl:rounded-b-large border border-blue-borderColor border-opacity-50 bg-opacity-50">
                <div className="flex flex-col space-y-2 justify-between items-center xl:flex-row xl:space-x-2 xl2:space-x-10">
                    <div className="flex flex-col items-center xl:items-start w-auto xl:w-2/6 xl2:ml-12 xl2:w-3/4">
                        <img
                            src={productLogo}
                            alt="product logo"
                            className="w-2/5 mb-5"
                        />
                        <Heading1
                            text={bannerHeader}
                            classes="text-xl text-center xl:text-left xl2:text-xl3"
                        />
                        <Paragraph
                            text={bannerText}
                            classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                        />
                        <div className="flex space-x-2 mt-10">
                            <Link
                                text="Get Started"
                                mode="primary"
                                href={`https://${
                                    category || "www"
                                }.permianchain.com/signup`}
                            />
                            <Link
                                isBlank
                                text="Demo Session "
                                mode="secondary"
                                classes="bg-blue-extralight"
                                href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            />
                        </div>
                    </div>

                    <div className="">
                        <img src={bannerIllustration} alt="illustration"></img>
                    </div>

                    <div className="flex flex-row items-center justify-center space-x-8 xl:flex-col xl:space-x-0 xl:space-y-8 xl:pl-4 xl2:px-20">
                        <SocialIcons color="main" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
