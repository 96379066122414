import React from "react"
import { Heading2 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"

export default function MemberCard({
    memberImage,
    memberName,
    memberPosition,
}) {
    return (
        <div className="group flex flex-col text-center space-y-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
            <img src={memberImage} alt="team-member" />
            <Heading2 text={memberName} classes="text-md2 " />
            <Paragraph text={memberPosition} classes="text-sm" />
        </div>
    )
}
