import React from "react"

import { Heading1 } from "../components/elements/Headings"
import Footer from "../components/layout/Footer"
import { Helmet } from "react-helmet"

export default function Privacy() {
    return (
        <>
            <Helmet>
                <title>
                    Natural Resources Tokenization Platform - PermianChain
                    Technologies Inc.
                </title>
                <meta
                    name="title"
                    property="og:title"
                    content="Natural Resources Tokenization Platform - PermianChain Technologies
                    Inc."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/terms-of-use"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/terms-of-use"
                />
            </Helmet>
            <div className="grid grid-cols-1 justify-around items-center gap-x-12">
                <div className="flex flex-col  sm:flex-row items-center bg-gray-lightGray mt-24 lg:mt-36 mx-6 lg:mx-48" />
                <div className="p-8 md:p-24">
                    <Heading1
                        text="Privacy Policy"
                        classes="mb-16 text-center text-xl lg:text-xl2 font-heavy"
                        color="black"
                    />

                    <p className="mt-5 text-justify">
                        Permianchain.com takes the privacy and security of your
                        personal information very seriously, and we are
                        committed to protecting and respecting your privacy.
                    </p>
                    <p className="mt-5 text-justify">
                        "Permianchain.com", “we”, “our”, “or” “us” throughout
                        this Privacy Policy (the “Policy”), we are referring to
                        PermianChain Technologies Inc. and any one of our
                        affiliates, subsidiaries, or related entities
                        (“PermianChain Group”). PermianChain Group entities are
                        registered in Canada. We are the data controller of your
                        personal information unless we expressly state
                        otherwise.
                    </p>
                    <p className="mt-5 text-justify">
                        This Policy does not apply to the extent we process
                        personal information in the role as a data processer
                        with respect to the PermianChain Investor platform (the
                        “Investor Platform”), which is licensed exclusively to
                        exempt market dealers (“EMD”). All information processed
                        by us is done so on behalf of the EMD who is the
                        responsible controller of any personal information.
                        PermianChain has no relationship with any investor,
                        issuer or other party or market intermediary that uses
                        the Investor Platform. We are not responsible for and
                        have no control over the privacy and data security
                        practices of any EMD, which may be different from those
                        set forth in this Policy. If you wish to exercise any
                        rights you may have under applicable laws regarding your
                        personal information provided through the Investor
                        Platform, please inquire directly with your EMD.
                    </p>
                    <p className="mt-5 text-justify">
                        Our Policy explains what personal information we collect
                        from you, how we store it and use it. It also covers how
                        we treat information you give to us. It contains
                        important information about your rights. Please read the
                        Policy carefully, so you understand and feel comfortable
                        with how we treat your personal information. By engaging
                        with us in the ways set out in this Policy, you confirm
                        that you have read and understood the entirety of this
                        Policy, as it applies to you.
                    </p>
                    <div className="privacy-list-content">
                        <ul className="list-disc pl-4 mt-5">
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    WHO WE ARE
                                </h5>
                                <p className="mt-5 text-justify">
                                    This Policy is provided on behalf of
                                    PermianChain Technologies Inc., its
                                    affiliates, and related entities.
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    COLLECTING YOUR INFORMATION
                                </h5>
                                <p className="mt-5 text-justify">
                                    We collect and process your information in
                                    these circumstances:
                                </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>
                                        When you give us your personal
                                        information by phone, email, via our
                                        website or our email marketing website
                                        MailChimp.com, in writing or otherwise.
                                        This includes but is not limited to
                                        information you give us when you
                                        register an account, register for email
                                        alerts, request literature or signup for
                                        newsletters. The information you give us
                                        can include your name, address, email
                                        address and payment information.
                                    </li>
                                    <li>
                                        When you visit our websites, we
                                        automatically collect information which
                                        includes: the Internet Protocol (IP)
                                        address used to connect your computer to
                                        the internet, your login information,
                                        your geographic location, your browser
                                        and browser plug-in type and version,
                                        and your operating system and platform.
                                        We also collect information each time
                                        you visit for fraud detection and
                                        prevention purposes to monitor for
                                        potential breaches to your account, and
                                        to identify any malicious software that
                                        may affect users of our website and
                                        services.
                                    </li>
                                    <li>
                                        To provide you with the necessary
                                        functionalities required during your use
                                        of our websites and services, and to
                                        review capacity requirements and
                                        compliance with the applicable usage
                                        terms.
                                    </li>
                                    <li>
                                        When you use our website to affect and
                                        fulfil service requests.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    HOW WE USE YOUR INFORMATION
                                </h5>
                                <p className="mt-5 text-justify">
                                    We use your personal information in the
                                    following ways:
                                </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>
                                        To improve the quality of our services
                                        and to train our staff.
                                    </li>
                                    <li>
                                        To let you know about any changes we
                                        make to our service.
                                    </li>
                                    <li>
                                        Gathering anonymized data for analysis
                                        and research, and to provide management
                                        information or other services internally
                                        and to third parties.
                                    </li>
                                    <li>
                                        To administer our website and for
                                        internal operations, including
                                        troubleshooting, data analysis, load
                                        management, testing, research,
                                        statistical and survey purposes.
                                    </li>
                                    <li>
                                        To improve our sites to make sure that
                                        our content is as effective as we can
                                        for you and for your computer.
                                    </li>
                                    <li>
                                        As part of our efforts to keep our site
                                        safe and secure and to prevent and
                                        detect money laundering, financial
                                        crime, and other crime, which may
                                        include, without limitation, investing
                                        suspicious activity.
                                    </li>
                                    <li>
                                        Promoting safety and security of our
                                        services, systems, and applications and
                                        in protecting our rights and rights of
                                        others.
                                    </li>
                                    <li>
                                        To monitor, record, store and use any
                                        telephone, email, or other communication
                                        with you. We will update your records
                                        with any new information you give us,
                                        and we will add it to any information we
                                        already have.
                                    </li>
                                    <li>
                                        When you call, we collect the Calling
                                        Line Identification information and keep
                                        a copy of the call for training and
                                        security purposes. We will also use your
                                        information to help improve our
                                        efficiency and effectiveness.
                                    </li>
                                    <li>
                                        To deal with any enquiries or issues you
                                        have about how we collect, store, and
                                        use your information, or any requests
                                        made by you for a copy of the
                                        information we hold about you.
                                    </li>
                                    <li>
                                        For internal corporate reporting,
                                        business administration, ensuring
                                        adequate insurance coverage for our
                                        business, ensuring the security of
                                        company facilities, research, and
                                        development, and to identify and
                                        implement business efficiencies.
                                    </li>
                                    <li>
                                        To provide you with information about
                                        the services that you request from us.
                                    </li>
                                    <li>
                                        To fulfill the purposes for which you
                                        provided the information or that were
                                        described when collected, or any other
                                        purpose for which you provide it.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    INFORMATION SECURITY
                                </h5>
                                <p className="mt-5 text-justify">
                                    We take the security of your personal
                                    information very seriously, and we use
                                    appropriate technologies and procedures to
                                    protect your personal information. We review
                                    our policies regularly and update them
                                    whenever needed to protect you, and to meet
                                    our business needs, changes in technology,
                                    and regulatory requirements. While no method
                                    of storage or transmission is 100% secure,
                                    here are examples of the ways we protect
                                    your data:
                                </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>
                                        We have appropriate technical and
                                        organisational measures in place to
                                        protect you against accidental loss and
                                        unauthorised access, use, destruction,
                                        or disclosure of your data.
                                    </li>
                                    <li>
                                        We have a business continuity and
                                        disaster recovery plan that is designed
                                        to help us offer our services and
                                        protect our people and assets no matter
                                        what happens.
                                    </li>
                                    <li>
                                        We place appropriate restrictions on
                                        access to personal information.
                                    </li>
                                    <li>
                                        We implement measures and controls,
                                        including monitoring and physical
                                        measures, to store and transfer data
                                        securely.
                                    </li>
                                    <li>
                                        We complete data protection impact
                                        assessments in accordance with legal
                                        requirements and our business policies.
                                    </li>
                                    <li>
                                        We provide data security training for
                                        our employees.
                                    </li>
                                    <li>
                                        We use a stringent approach to vendor
                                        risk management. The internet is an open
                                        medium and we cannot guarantee that any
                                        information you send to us by email or
                                        via our sites won’t be intercepted or
                                        tampered with. Any transmission is at
                                        your own risk. To help protect your
                                        personal information and minimise the
                                        risk of it being intercepted by
                                        unauthorised third parties, we have
                                        secure servers so that when you submit
                                        information to us through our site it is
                                        protected. Once we have received your
                                        information, we use strict procedures
                                        and security features to try to prevent
                                        unauthorised access.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    OUR LEGAL BASIS FOR USING YOUR INFORMATION
                                </h5>
                                <p className="mt-5 text-justify">
                                    Our legal basis for collecting and using
                                    your personal information depends on the
                                    exact type of information and how and when
                                    we collect it. However, we will normally
                                    only collect personal information from you
                                    where one of the following applies:
                                </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>
                                        It is in our legitimate interests or the
                                        legitimate interests of others (for
                                        example, to ensure the security of our
                                        website). Our legitimate interests are
                                        to:
                                        <ul className="list-disc pl-4 mt-2">
                                            <li>
                                                run, grow and develop our
                                                business;
                                            </li>
                                            <li>
                                                ensure a safe environment for
                                                our staff and website visitors;
                                            </li>
                                            <li>
                                                provide services to EMDs; and,
                                            </li>
                                            <li>
                                                for internal group
                                                administrative purposes.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        we have a legal obligation to collect
                                        your personal information (such as for
                                        tax reporting or to comply with all
                                        regulatory requirements which apply to
                                        us).
                                    </li>
                                    <li>
                                        Pursuant to a contract for services
                                        provided by us to you.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    HOW AND WHEN WE SHARE YOUR INFORMATION
                                </h5>
                                <p className="mt-5 text-justify">
                                    We will disclose your information to third
                                    parties:
                                </p>
                                <p className="mt-5 text-justify"> </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>
                                        where it is in our legitimate interests
                                        to do so to run, grow and develop our
                                        business:
                                    </li>
                                    <li>
                                        If we are under a duty to disclose or
                                        share your personal information: in
                                        order to comply with any legal
                                        obligation, any lawful request from
                                        government, judicial bodies or agencies
                                        to make sure we comply with our legal
                                        and regulatory obligations; with law
                                        enforcement officials; and as may be
                                        required to meet national security or
                                        law enforcement requirements or prevent
                                        illegal activity; to work with fraud
                                        prevention agencies, other companies and
                                        organisations to prevent or detect
                                        financial and other crime.
                                    </li>
                                    <li>
                                        In order to enforce or apply our terms
                                        and conditions or any other agreement,
                                        including any SaaS agreements to which
                                        we and you are privy to, or to respond
                                        to any claims, to protect our rights or
                                        the rights of a third-party, to protect
                                        the safety of any person or to prevent
                                        any illegal activity. When we share your
                                        information with third parties, we make
                                        sure the appropriate safeguards are in
                                        place to protect your personal
                                        information. We will never sell, trade,
                                        or rent your personal information to
                                        anyone.
                                    </li>
                                    <li>
                                        To fulfil the purpose for which you
                                        provide it.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    INTERNATIONAL DATA TRANSFERS{" "}
                                </h5>
                                <p className="mt-5 text-justify">
                                    We usually store your information on our
                                    secure servers or with our hosting
                                    provider’s server, in our case, we use
                                    Amazon Web Services (AWS), in Canada.
                                </p>
                                <p className="mt-5 text-justify">
                                    If you are a resident of the EEA, we may
                                    transfer your personal information to be
                                    held on our servers in Canada, a country
                                    which has been found by the European
                                    Commission to provide adequate protection
                                    for personal information. For further
                                    information about our international data
                                    transfer practices, and to consult the said
                                    contractual measures, please contact us
                                    using the contact details below.
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    HOW LONG WE STORE YOUR PERSONAL INFORMATION
                                </h5>
                                <p className="mt-5 text-justify">
                                    We keep the personal information we collect
                                    from you, where we have an ongoing
                                    legitimate business need to do so (for
                                    example, to provide your EMD with a service
                                    it has requested or to comply with
                                    applicable legal, tax or accounting
                                    requirements).
                                </p>
                                <p className="mt-5 text-justify">
                                    When we have no ongoing legitimate business
                                    need to hold your personal information, we
                                    will either delete or anonymise it. If we
                                    can’t do this (for example, because your
                                    personal information is stored in backup
                                    archives), we’ll securely store your
                                    personal information, only use it for a
                                    purpose we’ve already communicated to you,
                                    and isolate it from any further processing
                                    until archives are deleted.
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    YOUR RIGHTS
                                </h5>{" "}
                                <p className="mt-5 text-justify"> </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>
                                        Right of access: You have the right of
                                        access to any personal information we
                                        hold about you. You can ask us for a
                                        copy of your personal information;
                                        confirmation whether your personal
                                        information is being used by us; details
                                        about how and why it is being used; and
                                        (if you are a resident of the EU)
                                        details of what safeguards are in place
                                        if we transfer your information outside
                                        of the European Economic Area.
                                    </li>
                                    <li>
                                        Right to update your information: If any
                                        of the information we hold is
                                        inaccurate, you can ask us to make any
                                        necessary amendments.
                                    </li>
                                    <li>
                                        Complaints: If you have any complaints
                                        or concerns about our handling of your
                                        personal information please do get in
                                        touch with us and we will do our best to
                                        resolve these.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                                <p className="mt-5 text-justify">
                                    If you are a resident of the EU you have the
                                    right to complain about our collection and
                                    use of your personal information to the
                                    Information Commissioner's Office or the
                                    data protection authority in the country
                                    where you usually live or work. In certain
                                    specific circumstances you also have
                                    following further rights:
                                </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>
                                        Right of deletion: You have a right to
                                        ask us to delete any personal
                                        information which we are holding about
                                        you.
                                    </li>
                                    <li>
                                        Right to restrict use of your
                                        information: You have a right to ask us
                                        to restrict the way that we process your
                                        personal information.
                                    </li>
                                    <li>
                                        Right to data portability: You have a
                                        right to ask us to provide your personal
                                        information to a third-party provider of
                                        services.
                                    </li>
                                    <li>
                                        Right to object. You have a right to ask
                                        us to consider any valid objections
                                        which you have to our use of your
                                        personal information where we process
                                        your personal information on the basis
                                        of our or another person's legitimate
                                        interest.
                                    </li>
                                    <li>
                                        Right to stop marketing: You have a
                                        right to ask us to stop using your
                                        personal information for
                                        direct-marketing purposes.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                                <p className="mt-5 text-justify">
                                    We will consider all such requests and
                                    provide our response within a reasonable
                                    period (and in any event within one month of
                                    your request, unless we tell you we are
                                    entitled to a longer period required by
                                    applicable law).
                                </p>
                                <p className="mt-5 text-justify">
                                    Please note, however, that certain personal
                                    information may be exempt from such requests
                                    in certain circumstances, for example if we
                                    need to keep using the information to comply
                                    with our own legal obligations or to
                                    establish, exercise or defend legal claims.
                                </p>
                                <p className="mt-5 text-justify">
                                    If an exception applies, we will tell you
                                    this when responding to your request. We may
                                    request you provide us with information
                                    necessary to confirm your identity before
                                    responding to any request you make.
                                </p>
                                <p className="mt-5 text-justify">
                                    Please contact us at using the details below
                                    if you would like further information or if
                                    you would like to exercise any of your
                                    rights you can contact us using the details
                                    below.
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    USING OUR WEBSITE
                                </h5>
                                <p className="mt-5 text-justify">
                                    This Policy applies when you use our website
                                    and its content.
                                </p>
                                <p className="mt-5 text-justify">
                                    If you visit our website you acknowledge
                                    that the practices described in this Policy
                                    apply. Our sites can, from time to time,
                                    contain links to and from the websites of
                                    our partner networks, advertisers and
                                    affiliates. If you follow a link to any of
                                    these websites, please remember that they
                                    have their own policies and that we do not
                                    accept any responsibility or liability for
                                    their policies or how they collect and use
                                    your information.
                                </p>
                                <p className="mt-5 text-justify">
                                    Our websites let you share via third-party
                                    social media applications, such as Facebook
                                    and Twitter. These social media apps can
                                    collect and use information about how you
                                    use our site. Social media companies can
                                    collect and use any personal information
                                    that you provide via social media apps
                                    according to their own privacy policies, and
                                    you should read these carefully before you
                                    submit any personal information to our
                                    website. We don’t have control over, or
                                    responsibility for, those companies or how
                                    they use your information.
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    CHILDREN
                                </h5>
                                <p className="mt-5 text-justify">
                                    We do not and will not knowingly collect
                                    information from any unsupervised child
                                    under the age of 13. If you are under the
                                    age of 13, you may not use our sites unless
                                    your parent or guardian has provided us with
                                    their consent for your use of our sites or
                                    our site (as applicable).
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    Cookies
                                </h5>
                                <p className="mt-5 text-justify">
                                    When you access our website or content, we
                                    or companies we work with may place small
                                    data files called cookies on your computer
                                    or other device. We use these technologies
                                    to:
                                </p>
                                <ul className="list-disc pl-4 mt-2">
                                    <li>recognize you as an account holder;</li>
                                    <li>
                                        customize our website, content, and
                                        advertising;
                                    </li>
                                    <li>
                                        measure promotional effectiveness; and
                                    </li>
                                    <li>
                                        collect information about your computer
                                        or other access device to mitigate risk,
                                        help prevent fraud, and promote trust
                                        and safety.
                                    </li>
                                </ul>
                                <p className="mt-5 text-justify"></p>
                                <p className="mt-5 text-justify">
                                    We use both session and persistent cookies
                                    when you access our website or content.
                                    Session cookies expire and no longer have
                                    any effect when you log out of your account
                                    or close your browser. Persistent cookies
                                    remain on your browser until you erase them,
                                    or they expire.
                                </p>
                                <p className="mt-5 text-justify">
                                    We also use local shared objects, commonly
                                    referred to as "Flash cookies," to help
                                    ensure that your account security is not
                                    compromised, to spot irregularities in
                                    behaviour to help prevent fraud, and to
                                    support our sites and services.
                                </p>
                                <p className="mt-5 text-justify">
                                    We encode our cookies so that only we can
                                    interpret the information stored in them.
                                    You are free to decline our cookies if your
                                    browser or browser add-on permits but doing
                                    so may interfere with your use of the
                                    website. The help section of most browsers
                                    or browser add-ons provides instructions on
                                    blocking, deleting, or disabling cookies.
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    UPDATING THIS PRIVACY POLICY
                                </h5>
                                <p className="mt-5 text-justify">
                                    We will update or amend this Policy from
                                    time to time, to comply with law or to meet
                                    our changing business requirements. You can
                                    see when our most recent update to this
                                    Policy was by checking the "Last updated"
                                    note at the top of this Policy. If we need
                                    to make substantial changes to this Policy,
                                    we will notify you, if we hold your contact
                                    details, by post or by email, before we make
                                    any changes unless we are required to make
                                    the change sooner (for example regulatory
                                    reasons).
                                </p>
                            </li>
                            <li>
                                <h5 className="mt-5 font-heavy text-base md:text-xl">
                                    CONTACTING US
                                </h5>
                                <p className="mt-5 text-justify">
                                    If you have any questions about your privacy
                                    with us or this Policy, you can email us,
                                    write to us or call us at {` `}
                                    <a
                                        className="text-red-400 underline"
                                        href="mailto:contact@permianchain.com"
                                    >
                                        contact@permianchain.com
                                    </a>
                                    .
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
