import React from "react"

import Banner from "../../components/layout/products/Banner"
import CallToAction from "../../components/layout/CallToAction"
import Footer from "../../components/layout/Footer"

import MinerIllustration from "../../images/products/miner-illustration.png"

import NoBgSection from "../../components/layout/products/NoBgSection"
import BgSection from "../../components/layout/products/BgSection"

import firstIllustration from "../../images/products/miner/miner-1.png"
import secondIllustration from "../../images/products/miner/miner-2.png"
import thirdIllustration from "../../images/products/miner/miner-3.png"
// import fourthIllustration from "../../images/products/miner/miner-4.png";
import { Helmet } from "react-helmet"

export default function Miner() {
    return (
        <div className="flex flex-col space-y-12">
            <Helmet>
                <title>
                    PermianChain Miner: a Bitcoin Mining Platform and Digital
                    Energy Exchange
                </title>
                <meta
                    name="description"
                    property="og:description"
                    content="A bitcoin mining platform with an integrated digital energy exchange. A simple way to mine bitcoin profitably, while supplying our customers with responsibly produced energy to power the blockchain economy."
                />
                <meta
                    name="title"
                    property="og:title"
                    content="PermianChain Miner: a Bitcoin Mining Platform and Digital
                    Energy Exchange"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/products-miner"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/products-miner"
                />
            </Helmet>
            <Banner
                logo="3"
                bannerHeader="Offers a smart mining pool with an integrated energy marketplace for affordable digital-asset mining"
                bannerText="Enabling digital-asset miners to purchase digitally streamlined, automated electricity for onsite server farms using smart contracts to help data miners secure future power consumption at low-cost pricing."
                bannerIllustration={MinerIllustration}
                category="miner"
            />
            <NoBgSection
                smallHeadingText="Permianchain Miner"
                headingText="Affordable Electricity"
                paragraphText="PermianChain Miner connects data miners to consistent, low-cost power"
                illustration={firstIllustration}
            />
            <BgSection
                illustration={secondIllustration}
                smallHeadingText="Permianchain Miner"
                headingText="Efficient Mining Management"
                paragraphText="PermianChain Miner is an integrated solution for digital asset mining"
            />
            <NoBgSection
                smallHeadingText="Permianchain Miner"
                headingText="Tokenized Marketplace"
                paragraphText="PermianChain Miner lets data miners and energy companies trade tokenized power"
                illustration={thirdIllustration}
            />
            {/* <BgSection
        illustration={fourthIllustration}
        smallHeadingText="Permianchain Miner"
        headingText="PermianChain Exchange enables investors lorem ispum dolor"
        paragraphText="PermianChain Miner lets data miners and energy companies trade tokenized power"
      /> */}
            <CallToAction category="miner" />
            <Footer />
        </div>
    )
}
