import React from "react"

import Paragraph from "../../../elements/Paragraph"

export default function OverviewsCard({ icons }) {
    return (
        <div className="flex flex-col space-y-12 xl:space-y-48 items-center xl:pl-8">
            <div className="space-y-4 flex flex-col items-center xl:items-start ">
                <img src={icons[0]} alt="icon" />
                <Paragraph
                    text="The oil and gas sector is phenomenally complex. Natural resources need to be sourced from producers transported, stored, blended and delivered"
                    classes="font-medium text-xs2 text-center xl:text-left "
                />
            </div>

            <div className="space-y-4 flex flex-col items-center xl:items-start ">
                <img src={icons[1]} alt="icon" />
                <Paragraph
                    text="PermianChain’s blockchain-based
            gas-to-power marketplace lets
            users buy and sell field-generated
            power quickly and efficiently.
            
            "
                    classes="font-medium text-xs2 text-center xl:text-left  "
                />
            </div>
        </div>
    )
}
