import React from "react"
import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

import DecIllustration from "../../../images/services-dec-illustration.png"

export default function AssetTokenization() {
    return (
        <div className="grid xl:grid-cols-2 space-y-12 items-center justify-items-center py-24 my-12">
            <img src={DecIllustration} alt="dec" className="w-3/4 sm:w-auto" />

            <div className="flex flex-col items-center xl:items-start xl2:px-56 bg-at-diamond bg-no-repeat bg-left">
                <Heading6
                    text="Our services"
                    classes="text-blue-main text-center xl:text-left uppercase my-4"
                />
                <Heading2
                    text="Digital Energy Conservation Solutions"
                    classes="text-center xl:text-left text-xl lg:text-xl"
                />

                <Paragraph
                    text="Our digital energy conservation solution allows upstream companies to host and operate high-performance data centres at their oil and gas production sites, powering them with the previously wasted gas. We partner with data centre suppliers to fabricate Mobile Mining Units, which are about the size of a shipping container and can be placed unobtrusively on-site, to operate blockchain servers and cloud infrastructure that power the world-wide web, big data analysis and artificial intelligence."
                    classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                />

                <Paragraph
                    text="It’s an approach that makes a wasted by-product into a revenue generating asset and reduces the environmental impact of methane emissions."
                    classes="text-xs2 leading-1 text-center font-regular text-blueGray-500 px-8 py-6 my-6 bg-blue-lightblue bg-opacity-60 rounded-sm  border border-dashed border-blue-main border-opacity-20 "
                />
            </div>
        </div>
    )
}
