import React from "react"

import Banner from "../../components/layout/products/Banner"
import CallToAction from "../../components/layout/CallToAction"
import Footer from "../../components/layout/Footer"

import ExchangeIllustration from "../../images/products/exchange-illustration.png"

import NoBgSection from "../../components/layout/products/NoBgSection"
import BgSection from "../../components/layout/products/BgSection"

import firstIllustration from "../../images/products/exchange/exchange-1.png"
import secondIllustration from "../../images/products/exchange/exchange-1.png"
// import thirdIllustration from "../../images/products/investor/investor-3.png";
import { Helmet } from "react-helmet"

export default function Exchange() {
    return (
        <div className="flex flex-col space-y-12">
            <Helmet>
                <title>
                    Permianchain Exchange - Natural Resources Investment and
                    Trading
                </title>
                <meta
                    name="description"
                    property="og:description"
                    content="PermianChain Exchange, from natural asset security tokens (NAST) to natural resource tokens (NRT). We enhance natural resources investment and create a more efficient energy trading environment."
                />
                <meta
                    name="title"
                    property="og:title"
                    content="Permianchain Exchange - Natural Resources Investment and
                    Trading"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/products-exchange"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/products-exchange"
                />
            </Helmet>
            <Banner
                logo="4"
                bannerHeader="Unlocking liquidity for untapped clean energy resources"
                bannerText="Explore a new era of energy trading on PermianChain Exchange, from natural asset security tokens (NAST) to natural resource tokens (NRT)"
                bannerIllustration={ExchangeIllustration}
                category="exchange"
            />
            <NoBgSection
                smallHeadingText="PermianChain Exchange "
                headingText="Enhancing natural resources investment"
                paragraphText="A trustworthy primary market for issuing natural asset security tokens (NASTs) to enhance natural resources investment environment and attract smart and sustainable private capital."
                illustration={firstIllustration}
            />
            <BgSection
                illustration={secondIllustration}
                smallHeadingText="PermianChain Exchange "
                headingText="Creating a more efficient energy trading environment"
                paragraphText="Natural resource tokens (NRTs) with utility coded in smart off-take agreements (SOTAs) for a more efficient energy trading environment, with near real-time blockchain enabled tracking system and improved digital currency payment mechanism."
            />
            {/* <NoBgSection
        smallHeadingText="PermianChain Exchange "
        headingText="PermianChain Exchange enables investors lorem ispum dolor"
        paragraphText="Explore a new era of energy trading on PermianChain Exchange, 
	from natural asset security tokens (NAST) to natural resource tokens (NRT)"
        illustration={thirdIllustration}
      /> */}

            <CallToAction category="exchange" />
            <Footer />
        </div>
    )
}
