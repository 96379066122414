import React from "react"
import { NavLink, Link } from "react-router-dom"
import { MenuIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { Menu } from "@headlessui/react"

import { ReactComponent as CalculatorIcon } from "../../images/icons/calc.svg"
// import { ReactComponent as Logo } from "../../images/logo.svg";
// eslint-disable-next-line
import Logo2 from "../../images/Logo-T.png"

// import { ReactComponent as SupplierLogo } from "../../images/products/supplier.svg";
// import { ReactComponent as MinerLogo } from "../../images/products/miner.svg";
// import { ReactComponent as DealerLogo } from "../../images/products/dealer.svg";
// import { ReactComponent as ExchangeLogo } from "../../images/products/exchange.svg";
// import { ReactComponent as InvestorLogo } from "../../images/products/investor.svg";

import SupplierLogo2 from "../../images/PermianSupplier.png"
import MinerLogo2 from "../../images/PermianMiner.png"
import DealerLogo2 from "../../images/PermianDealer.png"
import ExchangeLogo2 from "../../images/PermianExchange.png"
import InvestorLogo2 from "../../images/PermianInvestor.png"

function MainNavigation() {
    const [navOpen, setNavOpen] = React.useState(false)

    const ref = React.useRef(null)

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setNavOpen(false)
        }
    }

    React.useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)

        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    })

    const [navBackground, setNavBackground] = React.useState(false)
    const navRef = React.useRef()
    navRef.current = navBackground
    React.useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 50
            if (navRef.current !== show) {
                setNavBackground(show)
            }
        }
        document.addEventListener("scroll", handleScroll)
        return () => {
            document.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <header
            className={`z-50 fixed w-full px-12  ${
                navBackground
                    ? "bg-white border-b border-blueGray-200"
                    : "bg-blue-light"
            }`}
        >
            <div
                ref={ref}
                className="flex flex-wrap items-center justify-between"
            >
                <div className="w-full relative flex justify-between lg:w-auto lg:justify-between">
                    <Link to="/" className="mr-4 py-2">
                        {/* <Logo /> */}
                        <img src={Logo2} alt="Logo" className="w-56" />
                    </Link>
                    <button
                        className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                        type="button"
                        onClick={() => setNavOpen(!navOpen)}
                    >
                        <MenuIcon className="w-5" />
                    </button>
                </div>
                <div
                    ref={ref}
                    className={
                        "lg:flex flex-grow justify-around items-center" +
                        (navOpen ? " flex" : " hidden")
                    }
                >
                    <ul className="flex py-8 lg:py-0 space-y-5 space-x-0 flex-col lg:flex-row items-center lg:space-y-0 lg:space-x-12 xl:space-x-20 lg:ml-auto text-sm font-semibold text-blue-semilight">
                        <li>
                            <NavLink
                                to="/"
                                activeClassName="text-blue-main"
                                onClick={() => {
                                    setNavOpen(false)
                                }}
                            >
                                HOME
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/about"
                                activeClassName="text-blue-main"
                                onClick={() => {
                                    setNavOpen(false)
                                }}
                            >
                                ABOUT
                            </NavLink>
                        </li>
                        {/* <li>
              <NavLink to="/products" activeClassName="text-blue-main">
                PRODUCTS
              </NavLink>
            </li> */}
                        <Menu as="li" className="relative">
                            <Menu.Button className="inline-flex justify-center w-full font-semibold text-blue-semilight focus:outline-none">
                                PRODUCTS
                                <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                            <Menu.Items className="origin-top-left absolute right-0 inset-x-0 mt-2 w-56 flex flex-col items-center bg-white ring-1 ring-black ring-opacity-5 divide-y divide-blue-lightblue focus:outline-none">
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-supplier"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                            onClick={() => {
                                                setNavOpen(false)
                                            }}
                                        >
                                            {/* <SupplierLogo className="w-9/12 mx-auto " /> */}
                                            <img
                                                src={SupplierLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-miner"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                            onClick={() => {
                                                setNavOpen(false)
                                            }}
                                        >
                                            {/* <MinerLogo className="w-9/12 mx-auto" /> */}
                                            <img
                                                src={MinerLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-dealer"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                            onClick={() => {
                                                setNavOpen(false)
                                            }}
                                        >
                                            {/* <DealerLogo className="w-10/12 mx-auto" /> */}
                                            <img
                                                src={DealerLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-exchange"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                            onClick={() => {
                                                setNavOpen(false)
                                            }}
                                        >
                                            {/* <ExchangeLogo className="w-9/12 mx-auto" /> */}
                                            <img
                                                src={ExchangeLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-investor"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                            onClick={() => {
                                                setNavOpen(false)
                                            }}
                                        >
                                            {/* <InvestorLogo className="w-9/12 mx-auto" /> */}
                                            <img
                                                src={InvestorLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                        <li>
                            <NavLink
                                to="/services"
                                activeClassName="text-blue-main"
                                onClick={() => {
                                    setNavOpen(false)
                                }}
                            >
                                SERVICES
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/blog"
                                activeClassName="text-blue-main"
                                onClick={() => {
                                    setNavOpen(false)
                                }}
                            >
                                BLOG
                            </NavLink>
                        </li>

                        <li
                            className="py-2 px-5 bg-blue-mid text-xs2 border rounded border-blue-semiheavy"
                            onClick={() => {
                                setNavOpen(false)
                            }}
                        >
                            <Link
                                to="/mining-calculator"
                                className="flex justify-between  items-center"
                            >
                                <span className="pr-2 text-blue-semiheavy">
                                    CALCULATOR{" "}
                                </span>
                                <CalculatorIcon />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default MainNavigation
