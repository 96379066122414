import React from "react"

import Banner from "../../components/layout/products/Banner"
import CallToAction from "../../components/layout/CallToAction"
import Footer from "../../components/layout/Footer"

import InvestorIllustration from "../../images/products/investor-illustration.png"

import NoBgSection from "../../components/layout/products/NoBgSection"
import BgSection from "../../components/layout/products/BgSection"

import firstIllustration from "../../images/products/investor/investor-1.png"
import secondIllustration from "../../images/products/investor/investor-2.png"
// import thirdIllustration from "../../images/products/investor/investor-3.png";
import { Helmet } from "react-helmet"

export default function Investor() {
    return (
        <div className="flex flex-col space-y-12">
            <Helmet>
                <title>
                    PermianChain Investor: Digital Securities Issuance for E&P
                    Companies
                </title>
                <meta
                    name="description"
                    property="og:description"
                    content="PermianChain Investor platform allows financial services companies to sponsor and offer debt and equity investment opportunities as compliant digital securities."
                />
                <meta
                    name="title"
                    property="og:title"
                    content="PermianChain Investor: Digital Securities Issuance for E&P
                    Companies"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/products-investor"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/products-investor"
                />
            </Helmet>
            <Banner
                logo="5"
                bannerHeader="We make it simple	for accredited investors"
                bannerText="PermianChain Investor is a digital platform that makes it simple for accredited investors to communicate with registered exempt market dealers (EMDs)
	Changing the investment dynamic in the oil and gas sector"
                bannerIllustration={InvestorIllustration}
                category="investor"
            />
            <NoBgSection
                smallHeadingText="Permianchain Investor"
                headingText="Bringing efficiency and transparency"
                paragraphText="PermianChain Investor offers open, secure and direct private investment. The digital infrastructure that PermianChain is built on enhances the efficiency of the investment process, increasing transparency and reducing costs"
                illustration={firstIllustration}
            />
            <BgSection
                illustration={secondIllustration}
                smallHeadingText="PermianChain Investor"
                headingText="Reducing administrative and opportunity costs"
                paragraphText="The PermianChain platform streamlines the investment process, removing costs every step of the way. Ensuring compliance requirements are met, client onboarding processes and the process of launching offering documents are all enhanced"
            />
            {/* <NoBgSection
        smallHeadingText="Permianchain Investor"
        headingText="PermianChain Investor’s feature-rich dashboard  investors to:"
        paragraphText="PermianChain Investor is a digital platform that makes it simple for accredited investors to communicate with registered exempt market dealers (EMDs)."
        illustration={thirdIllustration}
      /> */}

            <CallToAction category="investor" />
            <Footer />
        </div>
    )
}
