import React from "react"

import Banner from "../../components/layout/products/Banner"
import CallToAction from "../../components/layout/CallToAction"
import Footer from "../../components/layout/Footer"

import SupplierIllustration from "../../images/products/supplier-illustration.png"

import NoBgSection from "../../components/layout/products/NoBgSection"
import BgSection from "../../components/layout/products/BgSection"

import firstIllustration from "../../images/products/supplier/supplier-1.png"
import secondIllustration from "../../images/products/supplier/supplier-2.png"
import thirdIllustration from "../../images/products/supplier/supplier-3.png"
import fourthIllustration from "../../images/products/supplier/supplier-4.png"
// import fifthIllustration from "../../images/products/supplier/supplier-5.png";
import { Helmet } from "react-helmet"

function Supplier() {
    return (
        <div className="flex flex-col space-y-12">
            <Helmet>
                <title>
                    PermianChain Supplier: Manage and Monetize Oil & Gas Natural
                    Resources
                </title>
                <meta
                    name="description"
                    property="og:description"
                    content="Natural resources tokenization, we allow E&P companies to create value in resource finance and Sustainability to transform the oil and gas natural energy resources are funded, produced, bought and sold."
                />
                <meta
                    name="title"
                    property="og:title"
                    content="PermianChain Supplier: Manage and Monetize Oil & Gas Natural
                    Resources"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/products-supplier"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/products-supplier"
                />
            </Helmet>
            <Banner
                logo="1"
                bannerHeader="PermianChain Supplier lets oil and gas companies tokenize and manage their resources"
                bannerText="work with their issuers and investors while reducing administrative burden."
                bannerIllustration={SupplierIllustration}
                category="supplier"
            />
            <NoBgSection
                smallHeadingText="Permianchain supplier"
                headingText="Evolutionary marketplace for natural gas"
                paragraphText="PermianChain Supplier creates a new market where E&P companies can sell field-generated electricity to data centre operators."
                illustration={firstIllustration}
            />
            <BgSection
                illustration={secondIllustration}
                smallHeadingText="PermianChain supplier"
                headingText="Trust protocol enhancing market efficiency"
                paragraphText="PermianChain Supplier enables E&P firms work with exempt market dealers (EMDs) to offer investment opportunities in the form of compliant digital securities on the blockchain."
            />
            <NoBgSection
                smallHeadingText="Permianchain supplier"
                headingText="Unlock untapped digital earnings"
                paragraphText="PermianChain Supplier makes it simple to register with digital currency mining pools and convert digital earnings from onsite data mining into cash-flows."
                illustration={thirdIllustration}
            />
            <BgSection
                illustration={fourthIllustration}
                smallHeadingText="PermianChain supplier"
                headingText="Increased Profitability"
                paragraphText="PermianChain Supplier allows E&P companies to benefit from automated arbitrage between natural gas (mcf/d) and electricity (kWh) using Smart Off-Take Agreements (SOTAs) in the form of Digital Energy Currency (DEC)."
            />
            {/* <NoBgSection
        smallHeadingText="Permianchain supplier"
        headingText="PermianChain Supplier enables investors "
        paragraphText="PermianChain Supplier creates a new market where E&P companies can sell field-generated electricity to data centre operators."
        illustration={fifthIllustration}
      /> */}
            <CallToAction category="supplier" />
            <Footer />
        </div>
    )
}

export default Supplier
