import React from "react"

// import { ReactComponent as SupplierLogo } from "../../../../images/products/supplier.svg";
import SupplierLogo2 from "../../../../images/PermianSupplier.png"

import { Link } from "../../../elements/Button"
import { Heading2 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"
import ProductFeature from "./ProductFeature"

import MarketIcon from "../../../../images/icons/market.png"
import MoneyUpIcon from "../../../../images/icons/moneyup.png"
import MoneyBagsIcon from "../../../../images/icons/moneybags.png"
import HandshakeIcon from "../../../../images/icons/handshake.png"

function Supplier({ illustration }) {
    return (
        <>
            <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center items-center pt-12 pb-14 pl-0 xl:pl-20">
                <div className="flex flex-col space-y-8">
                    <img
                        src={SupplierLogo2}
                        alt="Supplier Logo"
                        className="mx-auto lg:mx-0 w-2/6 mb-5"
                    />
                    <Heading2
                        text="Enabling exploration and production (E&P) companies to catalogue, manage and create value from their proven oil and gas reserves."
                        classes="text-md3 text-center lg:text-left"
                    />

                    <Paragraph
                        classes="opacity-60 text-center lg:text-left text-sm2"
                        text="Making it simple for exploration & production (E&P) companies and registered market dealers to work together to achieve sustainable resource financing."
                    />
                    <Paragraph
                        classes="opacity-60 text-center lg:text-left"
                        text="With an integrated digital marketplace making it simple for natural gas operators and data centre operators to digitally trade field generated electricity to power onsite server farms."
                    />
                    <Link
                        classes="mx-auto lg:mx-0 w-52 text-center"
                        text="get started"
                        mode="primary"
                        href="https://supplier.permianchain.com"
                    />
                </div>
                <img src={illustration} alt="illustration" />
            </div>
            <div className="flex flex-col xl:flex-row pl-0 xl:pl-20 pb-14 space-x-0 xl:space-x-14 space-y-14 xl:space-y-0 ">
                <ProductFeature
                    icon={MarketIcon}
                    headingText="Evolutionary marketplace for natural gas"
                    pText="Permianchain Supplier creates a new market where E&P companies can sell field-generated electricity to data center operations"
                />
                <ProductFeature
                    icon={MoneyUpIcon}
                    headingText="Increased netback and net benefits to E&P companies"
                    pText="PermianChain Supplier allows E&P companies to benefit from digitally streamlined and automated arbitrage between natural gas (mcf/d) and electricity (kWh) using Smart Off-take Agreements (SOTAs)."
                />
                <ProductFeature
                    icon={MoneyBagsIcon}
                    headingText="Unlock untapped digital earnings for optimised cash flows"
                    pText="Option to register to digital currency mining pools. Convert digital earnings from onsite data mining into cash-flows. By deploying company-owned data centres, E&Ps can optimize cash-flows from each data mining operation with the potential for funding working capital requirements."
                />
                <ProductFeature
                    icon={HandshakeIcon}
                    headingText="Trust protocol bringing together exempt market dealers (EMDs) and E&P companies"
                    pText="Enabling E&P issuers to work with EMDs to offer debt and equity investment opportunities as compliant digital securities in the form of smart contracts (i.e. ERC-20 tokens) on the blockchain (i.e. Ethereum) to accredited investors under available prospectus exemptions."
                />
            </div>
        </>
    )
}

export default Supplier
