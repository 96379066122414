import React from "react"

import Banner from "../../components/layout/products/Banner"
import CallToAction from "../../components/layout/CallToAction"
import Footer from "../../components/layout/Footer"

import DealerIllustration from "../../images/products/dealer-illustration.png"

import NoBgSection from "../../components/layout/products/NoBgSection"
import BgSection from "../../components/layout/products/BgSection"

import firstIllustration from "../../images/products/dealer/dealer-1.png"
import secondIllustration from "../../images/products/dealer/dealer-2.png"
// import thirdIllustration from "../../images/products/dealer/dealer-3.png";
import { Helmet } from "react-helmet"

function Dealer() {
    return (
        <div className="flex flex-col space-y-12">
            <Helmet>
                <title>
                    PermianChain Dealer helps Registered Exempt Market Dealers
                </title>
                <meta
                    name="description"
                    property="og:description"
                    content="PermianChain Dealers work with issuers and investors while reducing administrative burden. A simple route to issuing digital securities and Streamlined relations and communications with investors."
                />
                <meta
                    name="title"
                    property="og:title"
                    content="PermianChain Dealer helps Registered Exempt Market Dealers"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/products-dealer"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/products-dealer"
                />
            </Helmet>
            <Banner
                logo="2"
                bannerHeader="PermianChain Dealer helps Registered Exempt Market Dealers"
                bannerText="work with their issuers and investors while reducing administrative burden."
                bannerIllustration={DealerIllustration}
                category="dealer"
            />
            <NoBgSection
                smallHeadingText="PermianChain Dealer"
                headingText="Simple route to issuing digital securities"
                paragraphText="Registered EMDs can list the securities on the PermianChain Investor dashboard and offer them to accredited investors"
                illustration={firstIllustration}
            />
            <BgSection
                illustration={secondIllustration}
                smallHeadingText="PermianChain Dealer"
                headingText="Streamlined investor relations and communications"
                paragraphText="Creating a simple blockchain-based digital security issuance platform to reduce long-holding periods and eliminate the high barriers to entry."
            />
            {/* <NoBgSection
        smallHeadingText="PermianChain Dealer"
        headingText="PermianChain Dealer enables registered market dealers to:"
        paragraphText="PermianChain Dealer makes it simple to register with digital currency mining pools and convert digital earnings from onsite data mining into cash-flows."
        illustration={thirdIllustration}
      /> */}

            <CallToAction category="dealer" />
            <Footer />
        </div>
    )
}

export default Dealer
