import React from "react"
import CalculatorForm from "../components/layout/home/calculator/CalculatorForm"
import CallToAction from "../components/layout/CallToAction"
import Footer from "../components/layout/Footer"
import Heading1 from "../components/elements/Headings"
import { Helmet } from "react-helmet"

function Calculator() {
    return (
        <>
            <Helmet>
                <title>
                    Bitcoin Mining Profit Calculator | BTC Mining Calculator
                </title>
                <meta
                    name="description"
                    property="og:description"
                    content="Bitcoin Mining Calculator - Calculate Bitcoin (BTC) mining profitability accurately in real-time based on hashrate, power consumption, exchange rate & power cost."
                />
                <meta
                    name="title"
                    property="og:title"
                    content="Bitcoin Mining Profit Calculator | BTC Mining Calculator"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/mining-calculator"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/mining-calculator"
                />
            </Helmet>
            <div className="py-24 lg:px-12 bg-blue-lightblue ">
                <Heading1
                    text="Calculator"
                    classes="text-xl2 p-12 text-center xl2:text-xl3"
                />
                <CalculatorForm />
            </div>
            <CallToAction link="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&amp;id=cee50e37f4" />
            <Footer />
        </>
    )
}

export default Calculator
