/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react"

import { Heading1, Heading5 } from "../components/elements/Headings"
import Footer from "../components/layout/Footer"
import { getBlog } from "../services/BlogApi"
import { useParams } from "react-router-dom"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarPlus } from "@fortawesome/free-regular-svg-icons"
import { Helmet } from "react-helmet"

const options = {
    replace: domNode => {
        if (domNode.name === "p") {
            const children = domNode.children
            return (
                <div className="mt-5 text-justify">
                    {" "}
                    {domToReact(children, options)}{" "}
                </div>
            )
        }
        if (domNode.name === "h1") {
            const children = domNode.children
            return (
                <h1 className="mt-5 font-bold">
                    {" "}
                    {domToReact(children, options)}{" "}
                </h1>
            )
        }
        if (domNode.name === "h2") {
            const children = domNode.children
            return (
                <h2 className="mt-5 font-bold">
                    {" "}
                    {domToReact(children, options)}{" "}
                </h2>
            )
        }
        if (domNode.name === "a") {
            const children = domNode.children
            const props = attributesToProps(domNode.attribs)
            return (
                <a {...props} className="text-red-400 underline">
                    {" "}
                    {domToReact(children, options)}{" "}
                </a>
            )
        }
    },
}

function BlogDetail() {
    const [blog, setBlog] = useState(null)
    const { id } = useParams()

    const getBlogFrpmAPI = useCallback(async () => {
        try {
            const res = await getBlog(id)
            setBlog(res.data.data)
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        getBlogFrpmAPI()
    }, [getBlogFrpmAPI])

    return (
        <>
            {blog && (
                <Helmet>
                    <title>{`PermianChain | Blog | ${blog.title}`}</title>
                    <meta name="keywords" content={blog.keywords} />
                    <meta
                        name="description"
                        property="og:description"
                        content={blog.description}
                    />
                    <meta
                        name="title"
                        property="og:title"
                        content={`PermianChain | Blog | ${blog.title}`}
                    />
                    <meta property="og:type" content="article" />
                    <meta
                        property="og:url"
                        content={`https://permianchain.com/blog/${blog.slug}`}
                    />
                    <link
                        rel="canonical"
                        href={`https://permianchain.com/blog/${blog.slug}`}
                    />
                </Helmet>
            )}
            <div className="grid grid-cols-1 justify-around items-center gap-x-12">
                {blog && (
                    <div className="flex flex-col mt-36 lg:mx-12 p-12 lg:p-24">
                        <div className="mb-6">
                            <Heading1
                                text={blog.title}
                                classes="uppercase text-md2 lg:text-xl"
                            />
                        </div>
                        <div className="h-12 flex flex-row items-center">
                            <FontAwesomeIcon
                                icon={faCalendarPlus}
                                size="1x"
                                color="grey"
                            />
                            <Heading5
                                text={format(
                                    new Date(blog.createdAt),
                                    "dd MMMM yyyy",
                                )}
                                classes="text-opacity-60 italic text-sm lg:text-md ml-3"
                            />
                        </div>
                        <p classes="text-left opacity-60 whitespace-pre-wrap text-sm leading-loose">
                            {parse(blog.content, options)}
                        </p>
                    </div>
                )}
            </div>
            <Footer />
        </>
    )
}

export default BlogDetail
