import React, { useState } from "react"

import Miner from "./products/Miner"
import Supplier from "./products/Supplier"
import Investor from "./products/Investor"
import Dealer from "./products/Dealer"
import Exchange from "./products/Exchange"

import styles from "./ProductsSection.module.css"
import { Heading2, Heading6 } from "../../elements/Headings"
// import DropdownProducts from "./DropdownProducts";
import Paragraph from "../../elements/Paragraph"

import SupplierIllustration from "../../../images/products/supplier-illustration.png"
import MinerIllustration from "../../../images/products/miner-illustration.png"
import InvestorIllustration from "../../../images/products/investor-illustration-home.png"
import DealerIllustration from "../../../images/products/dealer-illustration.png"
import ExchangeIllustration from "../../../images/products/exchange-illustration.png"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

// eslint-disable-next-line
const products = [
    {
        name: "Supplier",
    },
]

function ProductsSection() {
    const [openTab, setOpenTab] = useState(1)

    // useEffect(() => {
    //   ScrollTrigger.matchMedia({
    //     "(min-width: 1280px)": function () {
    //       gsap
    //         .timeline({
    //           scrollTrigger: {
    //             trigger: ".trigger",
    //             scrub: true,
    //             pin: true,
    //             end: "bottom -250%",
    //             toggleActions: "play reverse play reverse",
    //             onUpdate: (e) => {
    //               if (e.progress < 0.2) {
    //                 setOpenTab(1);
    //               }
    //               if (e.progress > 0.2 && e.progress < 0.4) {
    //                 setOpenTab(2);
    //               }
    //               if (e.progress > 0.4 && e.progress < 0.6) {
    //                 setOpenTab(3);
    //               }
    //               if (e.progress > 0.6 && e.progress < 0.8) {
    //                 setOpenTab(4);
    //               }
    //               if (e.progress > 0.8 && e.progress < 1) {
    //                 setOpenTab(5);
    //               }
    //             },
    //           },
    //         })
    //         .to(".p1", { opacity: 1 })
    //         .to(".p1", { opacity: 0 })
    //         .to(".p2", { opacity: 1 })
    //         .to(".p2", { opacity: 0 })
    //         .to(".p3", { opacity: 1 })
    //         .to(".p3", { opacity: 0 });
    //     },
    //   });
    // }, []);

    return (
        <>
            <div className="lg:py-14 py-24 whitespace-pre-wrap">
                <Heading6
                    text="PermianChain Blocks:"
                    classes="text-blue-main text-center uppercase my-4 text-sm2"
                />
                <Heading2
                    text="The Foundation of the PermianChain Platform"
                    classes="text-center text-xl lg:text-xl2"
                />
                <div className="w-auto mx-auto">
                    <Paragraph
                        text=""
                        classes="text-center opacity-60 pt-4 whitespace-pre-wrap text-md"
                    />
                </div>
            </div>
            <div className="trigger flex flex-col lg:flex-row justify-between pt-12">
                <div className="pb-10 lg:py-16 lg:pl-24">
                    <h4 className="text-sm2 text-center lg:text-left font-bold text-blue-main leading-xl py-3">
                        PermianChain
                    </h4>
                    {/* Unordered List start */}
                    <ul
                        className="flex space-y-2 flex-wrap flex-col"
                        role="tablist"
                    >
                        {/* First tab */}
                        <li
                            className={`mr-2 last:mr-0 flex-auto text-center w-full lg:w-auto ${
                                openTab === 1
                                    ? " shadow  border border-blueBlack rounded-sm "
                                    : "list-none"
                            }`}
                        >
                            <a
                                className={
                                    "text-sm2 font-medium py-3 block leading-normal " +
                                    (openTab === 1
                                        ? "text-blueBlack"
                                        : "text-blue-semilight")
                                }
                                onClick={e => {
                                    e.preventDefault()
                                    setOpenTab(1)
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Supplier
                            </a>
                        </li>
                        {/* Second tab */}
                        <li
                            className={`mr-2 last:mr-0 flex-auto text-center w-full lg:w-auto ${
                                openTab === 2
                                    ? " shadow  border border-blueBlack rounded-sm "
                                    : "list-none"
                            }`}
                        >
                            <a
                                className={`text-sm2 font-medium py-3 rounded block leading-normal ${
                                    openTab === 2
                                        ? `${styles.activetab} text-blueBlack`
                                        : "text-blue-semilight"
                                }`}
                                onClick={e => {
                                    e.preventDefault()
                                    setOpenTab(2)
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Miner
                            </a>
                        </li>
                        {/* Third tab */}
                        <li
                            className={`mr-2 last:mr-0 flex-auto text-center w-full lg:w-auto ${
                                openTab === 3
                                    ? " shadow  border border-blueBlack rounded-sm "
                                    : "list-none"
                            }`}
                        >
                            <a
                                className={
                                    "text-sm2 font-medium py-3 rounded block leading-normal " +
                                    (openTab === 3
                                        ? "text-blueBlack"
                                        : "text-blue-semilight")
                                }
                                onClick={e => {
                                    e.preventDefault()
                                    setOpenTab(3)
                                }}
                                data-toggle="tab"
                                href="#link3"
                                role="tablist"
                            >
                                Investor
                            </a>
                        </li>
                        {/* Fourth tab */}
                        <li
                            className={`mr-2 last:mr-0 flex-auto text-center w-full lg:w-auto ${
                                openTab === 4
                                    ? " shadow  border border-blueBlack rounded-sm "
                                    : "list-none"
                            }`}
                        >
                            <a
                                className={
                                    "text-sm2 font-medium py-3 rounded block leading-normal " +
                                    (openTab === 4
                                        ? "text-blueBlack"
                                        : "text-blue-semilight")
                                }
                                onClick={e => {
                                    e.preventDefault()
                                    setOpenTab(4)
                                }}
                                data-toggle="tab"
                                href="#link4"
                                role="tablist"
                            >
                                Dealer
                            </a>
                        </li>
                        {/* Fifth tab */}
                        <li
                            className={`mr-2 last:mr-0 flex-auto text-center w-full lg:w-auto ${
                                openTab === 5
                                    ? " shadow  border border-blueBlack rounded-sm "
                                    : "list-none"
                            }`}
                        >
                            <a
                                className={
                                    "text-sm2 font-medium py-3 rounded block leading-normal " +
                                    (openTab === 5
                                        ? "text-blueBlack"
                                        : "text-blue-semilight")
                                }
                                onClick={e => {
                                    e.preventDefault()
                                    setOpenTab(5)
                                }}
                                data-toggle="tab"
                                href="#link5"
                                role="tablist"
                            >
                                Exchange
                            </a>
                        </li>
                    </ul>
                    {/* Unordered List end */}
                </div>
                <div className=" flex flex-col break-words bg-blue-light ml-0 lg:ml-28 mb-6 border border-blue-borderColor border-r-0 rounded-0 xl:rounded-tl-xl xl:rounded-bl-sm">
                    <div className="px-4 flex-auto">
                        <div
                            className="tab-content tab-space  "
                            id="tab-content"
                        >
                            <div
                                className={` ${
                                    openTab === 1 ? "block" : "hidden"
                                } p1`}
                                id="link1"
                            >
                                <Supplier illustration={SupplierIllustration} />
                            </div>
                            <div
                                className={`${
                                    openTab === 2 ? "block" : "hidden"
                                } p2`}
                                id="link2"
                            >
                                <Miner illustration={MinerIllustration} />
                            </div>
                            <div
                                className={`${
                                    openTab === 3 ? "block" : "hidden"
                                } p3`}
                                id="link3"
                            >
                                <Investor illustration={InvestorIllustration} />
                            </div>
                            <div
                                className={`${
                                    openTab === 4 ? "block" : "hidden"
                                } p4`}
                                id="link4"
                            >
                                <Dealer illustration={DealerIllustration} />
                            </div>
                            <div
                                className={`${
                                    openTab === 5 ? "block" : "hidden"
                                } p5`}
                                id="link5"
                            >
                                <Exchange illustration={ExchangeIllustration} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductsSection
