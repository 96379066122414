import React from "react"

// Elements
import { Heading1, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"
// import Button from "../../elements/Button";

// Proud member logos
import { ReactComponent as BlockhainLogo } from "../../../images/members/blockchain.svg"
import { ReactComponent as PtacLogo } from "../../../images/members/ptac.svg"

import SocialIcons from "../../SocialIcons"

function Banner() {
    return (
        <div>
            <div className=" bg-blue-light xl1:px-14 xl2:px-4 lg:pt-32 lg:pb-14 ">
                <div className="bg-blue-lightblue xl1:rounded-t-xl xl1:rounded-b-large border border-blue-borderColor border-opacity-50 bg-opacity-50">
                    <div className="pt-40 lg:pt-20 pb-12 xl2:mx-28 xl:px-12 bg-hero-illustration bg-no-repeat bg-right bg-auto ">
                        <div className="grid xl:grid-cols-7 gap-x-8 space-y-10 justify-around items-center xl:flex-row ">
                            <div className="col-span-3 flex flex-col items-center xl:items-start px-4">
                                <Heading1
                                    text="Creating Value in Resource Finance and Sustainability"
                                    classes="text-xl2 text-center xl:text-left xl3:text-xl3"
                                />
                                <Paragraph
                                    text="PermianChain brings together the blockchain economy and oil and gas sector to transform the way natural gas reserves are funded, produced, bought and sold."
                                    classes="mt-5 text-sm leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                                />

                                <div className="flex space-x-2 mt-10">
                                    <a
                                        href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&amp;id=cee50e37f4"
                                        // target="_blank"
                                        rel="noreferrer"
                                        className="text-center bg-blue-main text-white  px-4 lg:px-12 py-4 uppercase text-xs lg:text-xs2 font-bold rounded focus:outline-none transition duration-500 ease-in-out transform hover:-translate-y-1 "
                                    >
                                        Learn more
                                    </a>
                                    <a
                                        href="/El_Masri_Oil_Natural_Gas_and_Blockchain_Research_Institute.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-center border border-blue-heavy text-blue-heavy px-4 lg:px-12 py-4 uppercase text-xs lg:text-xs2 font-bold rounded focus:outline-none transition duration-500 ease-in-out transform hover:-translate-y-1 "
                                    >
                                        Case Study
                                    </a>
                                </div>
                            </div>
                            <div className="col-span-3 video-responsive">
                                <iframe
                                    width="800"
                                    height="500"
                                    src="https://www.youtube.com/embed/37FyyzKh2wI?autoplay=0&mute=1"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                    controls
                                    className="px-2 shadow-xxxl rounded-lg xl:border-t-6 xl:border-b-6 xl:border-l-4 xl:border-r-4 border-white border-opacity-30"
                                ></iframe>
                            </div>
                            {/* <video
            style={{ maxWidth: "100%", height: "auto" }}
            width="675"
            height="415"
            controls
            className="px-2 shadow-xxxl rounded-lg xl:border-t-8 xl:border-b-8 xl:border-l-6 xl:border-r-6 border-white border-opacity-30"
          >
            <source
              src="https://www.youtube.com/watch?v=37FyyzKh2wI"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video> */}

                            <div className="flex flex-row items-center col-span-3 xl:col-span-1 justify-center justify-items-center space-x-8 xl:flex-col xl:space-x-0 xl:space-y-8">
                                <SocialIcons color="main" />
                            </div>
                        </div>
                        <div className="flex flex-col xl:space-x-12 space-y-4 items-center mt-14 xl:flex-row  xl:space-y-0  xl2:ml-12">
                            <Heading6
                                text="proud member of:"
                                classes="uppercase text-xs"
                            />
                            <div className="flex flex-nowrap overflow-x-scroll xl:overflow-x-hidden ml-0 space-x-4 sm:space-x-12 items-center">
                                <BlockhainLogo className="w-24 sm:w-auto" />
                                <PtacLogo className="w-32 sm:w-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
