import React from "react"

import Button from "../../elements/Button"
import { Heading2 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

export default function JoinUs() {
    return (
        <div className="mt-24 xl:mx-64 lg:rounded-sm bg-about-join bg-cover bg-no-repeat bg-center ">
            <div className="flex flex-col xl:flex-row items-center justify-between justify-items-center px-12 xl:px-24 py-12">
                <div className="flex flex-col items-center xl:items-start">
                    <Heading2
                        text="Want to join Us?"
                        classes=" text-center md:text-left text-xl"
                        color="white"
                    />
                    <div className="xl:w-3/4">
                        <Paragraph
                            text="We have big ambitions for the next few years.
              If you’d like to join us"
                            classes=" opacity-60 py-4 whitespace-pre-wrap text-sm2 lg:text-md text-white text-center xl:text-left"
                        />
                    </div>
                </div>
                <Button
                    text="CONTACT US"
                    mode="secondary"
                    classes=" text-white "
                />
            </div>
        </div>
    )
}
