import React from "react"
import Banner from "../components/layout/home/Banner"
import ProductsSection from "../components/layout/home/ProductsSection"
import ServicesSection from "../components/layout/home/ServicesSection"
import CalculatorSection from "../components/layout/home/CalculatorSection"
import BlogSection from "../components/layout/home/BlogSection"
import CallToAction from "../components/layout/CallToAction"
import Footer from "../components/layout/Footer"
import { Helmet } from "react-helmet"

function Home() {
    return (
        <div>
            <Helmet>
                <title>
                    Natural Resources Tokenization Platform - PermianChain
                    Technologies Inc.
                </title>
                <meta
                    name="keywords"
                    content="bitcoin energy platform, natural resources tokenization platform, oil and gas tokenization, natural gas monetization, crypto oil and gas "
                />
                <meta
                    name="description"
                    property="og:description"
                    content="PermianChain, a natural resource tokenization platform that uses blockchain to enable exploration and production (E&P) companies to monetize natural gas reserves by issuing Natural Asset Security Tokens (NAST) and tokenized carbon credits from carbon offsets derived from bitcoin energy projects. "
                />
                <meta
                    name="title"
                    property="og:title"
                    content="Natural Resources Tokenization Platform - PermianChain
                    Technologies Inc."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://permianchain.com" />
                <link rel="canonical" href="https://permianchain.com" />
            </Helmet>
            <Banner />
            <ProductsSection color="blueGray" />
            <ServicesSection />
            <CalculatorSection />
            <BlogSection />
            <CallToAction link="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&amp;id=cee50e37f4" />
            <Footer />
        </div>
    )
}

export default Home
