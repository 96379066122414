import React from "react"
import { Heading2 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

export default function CompanyStatement() {
    return (
        <div className="grid xl:grid-cols-2 gap-y-32 justify-items-center items-center justify-center mt-24 xl2:pr-24">
            <div className="flex flex-col justify-center space-y-32">
                <div className="flex flex-col md:flex-row space-y-12 md:space-y-0 items-center justify-center">
                    <Heading2
                        text="Our mission"
                        classes="text-xl2 text-center border border-blue-borderColor px-4 py-2 rounded bg-blue-smdlight "
                    />
                    <Paragraph
                        text="We want to bring the efficiency of the blockchain to natural resources, enhancing transparency and profitability across the sector"
                        classes="w-auto md:w-2/6 mx-5 text-sm text-center md:text-left font-medium"
                    />
                </div>
                <div className="flex flex-col md:flex-row space-y-12 md:space-y-0 items-center justify-center">
                    <Heading2
                        text="Our vision"
                        classes="text-xl2 text-center border border-blue-borderColor px-8 py-2 rounded bg-blue-smdlight "
                    />
                    <Paragraph
                        text="We want to use technology to enhance the way that oil and gas is discovered, extracted managed and traded"
                        classes="md:w-2/6 mx-5 text-sm text-center md:text-left font-medium"
                    />
                </div>
            </div>
            <div className="video-responsive">
                <iframe
                    width="675"
                    height="415"
                    src="https://www.youtube.com/embed/37FyyzKh2wI?autoplay=0&mute=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    controls
                    className="px-2 shadow-xxxl rounded-lg xl:border-t-8 xl:border-b-8 xl:border-l-6 xl:border-r-6 border-white border-opacity-30"
                ></iframe>
            </div>
        </div>
    )
}
