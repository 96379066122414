import React from "react"

function Heading1({ text, classes, color }) {
    if (color) {
        return (
            <h1 className={`${classes} text-${color}  font-semibold`}>
                {text}
            </h1>
        )
    }
    return (
        <h1 className={`${classes} text-blueBlack  font-semibold`}>{text}</h1>
    )
}

function Heading2({ text, classes, color }) {
    if (color) {
        return (
            <h2 className={`${classes} text-${color}  font-semibold`}>
                {text}
            </h2>
        )
    }
    return (
        <h2 className={`${classes} text-blueBlack  font-semibold`}>{text}</h2>
    )
}

function Heading3({ text, classes }) {
    return <h3 className={`${classes}  font-regular`}>{text}</h3>
}

function Heading5({ text, classes }) {
    return <h5 className={`  font-medium ${classes}`}>{text}</h5>
}

function Heading6({ text, classes, fontWeight }) {
    if (fontWeight) {
        return <h6 className={`  font-${fontWeight} ${classes}`}>{text}</h6>
    }
    return <h6 className={`  font-bold ${classes}`}>{text}</h6>
}

export default Heading1
export { Heading1, Heading2, Heading3, Heading5, Heading6 }
