import React from "react"
import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"
import { Link } from "../../elements/Button"

import ServiceCard from "./services/ServiceCard"

// import AtService from "../../../images/services/serviceat.png";

export default function ServicesSection() {
    return (
        <div>
            <div className="lg:px-14 pt-24 whitespace-pre-wrap flex flex-col items-center space-y-20">
                <div>
                    <Heading6
                        text="What we offer"
                        classes="text-blue-main text-center uppercase my-4"
                    />
                    <Heading2
                        text="Our Services"
                        classes="text-center text-xl lg:text-xl2"
                    />
                    <div className="w-3/4 mx-auto">
                        <Paragraph
                            text=""
                            classes="text-center opacity-60 pt-4 whitespace-pre-wrap text-md"
                        />
                    </div>
                </div>

                <div className="flex flex-col xl:flex-row space-y-6 xl:space-y-0 items-center justify-around px-80 xl:space-x-6">
                    <ServiceCard
                        serviceIllustration="atoken-illustration"
                        headingText="Asset Tokenization"
                        pText="We are using the blockchain to tokenize confirmed deposits of natural resources so that they can be represented digitally as smart contracts on a blockchain. This means they can be traded quickly, efficiently and legally, without significant brokerage or administrative costs, far earlier than is currently possible. Blockchain-enabled functions allow for instant settlement, reduced holding periods, enhanced transparency, and digital automation."
                    />
                    <ServiceCard
                        serviceIllustration="deconversion-illustration"
                        headingText="Digital Energy Conversion"
                        pText="Our digital energy conservation solution allows upstream companies to host and operate blockchain data centres at their energy production sites, powering them with the previously wasted gas. It’s an approach that makes a wasted by-product into a revenue generating asset and reduces the environmental impact of methane emissions."
                    />
                    <ServiceCard
                        serviceIllustration="cmining-illustration"
                        headingText="Crypto Mining"
                        pText="PermianChain provides cryptocurrency mining management solutions for a variety of audiences. We make it simple to do it yourself or to take a stake in industrial grade data centres alongside prominent institutional investors. The economies of scale that we generate, coupled with our relationships and know-how, mean that we can host cryptocurrency mining servers on natural gas power sites on behalf of miners. Powering the servers with field-generated electricity means miners can generate cryptocurrencies at a low cost."
                    />
                </div>

                <Link
                    href="/services"
                    text="Learn more"
                    mode="secondary"
                    classes="mx-auto"
                />
            </div>
        </div>
    )
}
