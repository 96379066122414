import axios from "axios"

const baseUrl = "https://server-api.permianchain.com/api/blog"

export const getBlogs = async () => {
    return await axios.get(baseUrl)
}

export const getBlog = async id => {
    return await axios.get(`${baseUrl}/${id}`)
}
