import React from "react"
import { Heading2 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"

export default function ServiceCard({
    headingText,
    pText,
    serviceIllustration,
}) {
    return (
        <div
            className={`box-content flex flex-col items-center justify-center px-4 pt-12 space-y-7 text-center w-70 h-84 ${serviceIllustration}`}
        >
            <Heading2 text={headingText} classes="text-md3 font-semibold" />
            <Paragraph
                text={pText}
                classes="text-blue-serviceText px-14 sm:px-0 font-regular leading-7 text-sm inline"
            />
        </div>
    )
}
