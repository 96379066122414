import React from "react"

import DealerLogo2 from "../../../../images/PermianDealer.png"

import { Link } from "../../../elements/Button"
import { Heading2 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"
import ProductFeature from "./ProductFeature"

import MarketIcon from "../../../../images/icons/market.png"
import MoneyUpIcon from "../../../../images/icons/moneyup.png"

function Dealer({ illustration }) {
    return (
        <>
            <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center items-center pt-12 pb-14 pl-0 xl:pl-20">
                <div className="flex flex-col space-y-8 ">
                    <img
                        src={DealerLogo2}
                        alt="Miner Logo"
                        className="mx-auto lg:mx-0 w-2/6 mb-5"
                    />
                    <Heading2
                        text="Helps registered exempt market dealers
            work with their issuers and investors
            while reducing administrative burden."
                        classes="text-md3 text-center lg:text-left"
                    />

                    <Paragraph
                        classes="opacity-60 text-center lg:text-left text-sm2"
                        text="Enables EMDs to offer eligible accredited investors compliant digital securities in the form of smart contacts on the blockchain under available prospectus exemptions"
                    />

                    <Link
                        classes="mx-auto lg:mx-0 w-52 text-center"
                        text="get started"
                        mode="primary"
                        href="https://dealer.permianchain.com"
                    />
                </div>
                <img src={illustration} className="w-4/5" alt="illustration" />
            </div>
            <div className="flex flex-col xl:flex-row pl-0 xl:pl-20 pb-14 space-x-0 xl:space-x-14 space-y-14 xl:space-y-0 ">
                <ProductFeature
                    icon={MarketIcon}
                    headingText="Simple route to issuing
          digital securities"
                    pText="Registered EMDs can list the securities on the PermianChain Investor dashboard and offer them to accredited investors"
                />
                <ProductFeature
                    icon={MoneyUpIcon}
                    headingText="Streamlined investor relations and communications"
                    pText="Creating a simple blockchain-based digital security issuance platform to reduce long-holding periods and eliminate the high barriers to entry."
                />
            </div>
        </>
    )
}

export default Dealer
