import React from "react"
import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

export default function BgSection({
    illustration,
    smallHeadingText,
    headingText,
    paragraphText,
}) {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 xl2:grid-cols-4 gap-y-12 bg-products-bg-section bg-no-repeat bg-center items-center justify-items-center py-24">
            <img
                src={illustration}
                alt="illustration"
                className="col-span-1 xl2:col-span-2 md:pl-24"
            />

            <div className="flex flex-col items-center md:items-start md:ml-24 ">
                <Heading6
                    text={smallHeadingText}
                    classes="text-blue-main text-center md:text-left uppercase my-4"
                />
                <Heading2
                    text={headingText}
                    classes="text-center md:text-left text-xl lg:text-xl"
                />

                <Paragraph
                    text={paragraphText}
                    classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 md:text-left xl:text-sm"
                />
            </div>
            <div></div>
        </div>
    )
}
