import React from "react"

import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

export default function NoBgSection({
    smallHeadingText,
    headingText,
    paragraphText,
    illustration,
}) {
    return (
        <div className="grid grid-cols-1 gap-y-12 md:grid-cols-2 xl2:grid-cols-4 items-center justify-items-center px-12 xl2:mx-56">
            <div className="flex flex-col items-center md:items-start md:col-span-1 xl2:col-span-2 xl2:pl-12 xl2:pl-0 xl2:px-36">
                <Heading6
                    text={smallHeadingText}
                    classes="text-blue-main text-center sm:text-left uppercase my-4"
                />
                <Heading2
                    text={headingText}
                    classes="text-center sm:text-left text-xl lg:text-xl"
                />

                <Paragraph
                    text={paragraphText}
                    classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 sm:text-left xl:text-sm"
                />
            </div>
            <img
                src={illustration}
                alt="section illustration"
                className="col-span-1 xl2:col-span-2 "
            />
        </div>
    )
}
