import React from "react"
import { Heading6 } from "../../elements/Headings"

export default function WorkingWithUsCard({ number, heading }) {
    if (number !== "00") {
        return (
            <div className="flex flex-col xl1:flex-row items-center xl1:items-start justify-items-center xl1:space-x-8 py-12 pl-0 xl1:pl-12 ">
                <div className="mx-auto xl1:mx-0 rounded-full p-2 bg-blue-lightblue border border-dashed border-blue-main">
                    <p className=" text-sm font-medium">{number}</p>
                </div>
                <Heading6
                    text={heading}
                    classes="text-blue-main text-center xl1:text-left uppercase leading-10"
                    fontWeight="semibold"
                />
            </div>
        )
    }

    return <div className=" hidden xl1:block"></div>
}
