import React from "react"

import { Heading2, Heading6 } from "../../elements/Headings"

import CapitalizeIcon from "../../../images/icons/approach/Capitalize.png"
import ConserveIcon from "../../../images/icons/approach/Conserve.png"
import ConsultIcon from "../../../images/icons/approach/Consult.png"
import ConvertIcon from "../../../images/icons/approach/Convert.png"
import ApproachCard from "./approach/ApproachCard"

export default function Approach() {
    return (
        <div>
            <div className="lg:px-14 pt-24 whitespace-pre-wrap flex flex-col items-center space-y-40">
                <div className="">
                    <Heading6
                        text="Our approach"
                        classes="text-blue-main text-center uppercase my-4"
                    />
                    <Heading2
                        text="Consult, conserve, convert and capitalize, enabling digital business-models"
                        classes="text-center text-xl lg:text-xl"
                    />
                </div>
                <div className="grid md:grid-cols-2 gap-y-24 xl:grid-cols-4 divide-x-2 divide-blue-smdlight px-12 xl:px-24">
                    <ApproachCard
                        icon={ConsultIcon}
                        title="Consult"
                        text="Providing E&P companies with ongoing consulting during the different stages of development"
                    />
                    <ApproachCard
                        icon={ConserveIcon}
                        title="Conserve"
                        text="Making better use of natural resources by preserving ownership through tokenization strategies "
                    />
                    <ApproachCard
                        icon={ConvertIcon}
                        title="Convert"
                        text="Guide upstream companies through the development stages of converting resources to a digital store of value"
                    />
                    <ApproachCard
                        icon={CapitalizeIcon}
                        title="Capitalize"
                        text="Enabling registered market dealers to unlock access to new sources of capital"
                    />
                </div>
            </div>
        </div>
    )
}
