import React from "react"

import Banner from "../components/layout/services/Banner"
import AssetTokenization from "../components/layout/services/AssetTokenization"
import DecSolutions from "../components/layout/services/DecSolutions"
import CryptoMining from "../components/layout/services/CryptoMining"
import WorkingWithUs from "../components/layout/services/WorkingWithUs"
import CallToAction from "../components/layout/CallToAction"
import Footer from "../components/layout/Footer"
import { Helmet } from "react-helmet"

function Services() {
    return (
        <div className="flex flex-col">
            <Helmet>
                <title>Oil & Gas Resources Tokenization Platform</title>
                <meta
                    name="keywords"
                    content="digital asset mining, oil and gas"
                />
                <meta
                    name="description"
                    property="og:description"
                    content="PermianChain creates new strategies for the energy sector, digital asset miners, investors and societies. We make it easier and more efficient to invest in oil and gas."
                />
                <meta
                    name="title"
                    property="og:title"
                    content="Oil & Gas Resources Tokenization Platform"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/services"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/services"
                />
            </Helmet>
            <Banner />
            <AssetTokenization />
            <DecSolutions />
            <CryptoMining />
            <WorkingWithUs />
            <CallToAction link="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&amp;id=cee50e37f4" />
            <Footer />
        </div>
    )
}

export default Services
