import React from "react"

import { Heading3, Heading6 } from "../../elements/Headings"
import Button from "../../elements/Button"

import Icon1 from "../../../images/icons/whitepaper-overviews/icon1.png"
import Icon2 from "../../../images/icons/whitepaper-overviews/icon2.png"

import OverviewsCard from "./whitepaper/OverviewsCard"

export default function WhitePaperOverview() {
    return (
        <div className="mt-36 py-24 sm:px-8 xl:px-36 gap-x-12 space-y-12 grid sm:grid-cols-4 items-center justify-items-center justify-center divide-x divide-blue-lightblue bg-about-whitepaper bg-no-repeat bg-cover xl:ml-20">
            <div className="col-span-2 space-y-8 flex flex-col items-center xl:items-start">
                <Heading6
                    text="OVERVIEW"
                    classes="text-blue-main  uppercase my-4 text-center xl:text-left "
                />
                <Heading3
                    text="Making it simple for the oil and gas sector to trade efficiently, reducing costs and attracting new investors"
                    classes="text-xl lg:text-xl text-center xl:text-left "
                />
                <Button text="Whitepaper" mode="primary" classes="mx-auto" />
            </div>

            <div className="justify-items-center gap-x-12 col-span-2">
                <OverviewsCard icons={[Icon1, Icon2]} />
            </div>
        </div>
    )
}
