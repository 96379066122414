import React, { useEffect, useRef, useState } from "react"
import { Formik, Form, useField } from "formik"
// import * as Yup from "yup";
import Button from "../../../elements/Button"

import { ExclamationIcon } from "@heroicons/react/outline"
import Heading1 from "../../../elements/Headings"

// import _ from "lodash";
import { toast } from "react-toastify"
import Api from "../../../../calculator/services/Api"

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props)
    return (
        <>
            <div className="flex flex-col col-span-4 xl:col-span-1">
                <label
                    className="py-2 font-semibold text-sm opacity-90 text-blueBlack "
                    htmlFor={props.id || props.name}
                >
                    {label}
                </label>
                <div
                    className={`p-2 input-group flex flex-row items-center justify-between rounded-md bg-blue-xlblue bg-opacity-30 border ${
                        meta.error
                            ? "border border-red-500"
                            : "border-blue-main"
                    } ${
                        meta.error ? "transform-gpu" : ""
                    } border-opacity-25 text-blue-main font-medium`}
                >
                    <input
                        name={props.name}
                        type={props.type}
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        value={props.value}
                        readOnly={props.readOnly}
                        className="form-input  bg-transparent border-0 p-2  w-1/2 flex-shrink flex-grow "
                        {...field}
                        {...props}
                    />

                    <h5
                        className={`${
                            props.head ? "block " : "hidden "
                        } p-2 text-blueGray-400 `}
                    >
                        {props.head}
                    </h5>
                </div>
                {meta.touched && meta.error ? (
                    <div className="error text-red-500 mt-1 flex flex-row space-x-2 text-xs2">
                        <ExclamationIcon className="w-4" />
                        <span className="">{meta.error}</span>
                    </div>
                ) : null}
            </div>
        </>
    )
}

const MyTextInputWithSelect = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props)
    return (
        <>
            <div className=" flex flex-col col-span-4 xl:col-span-1 box-border">
                <label
                    className="py-2 font-semibold text-sm opacity-90 text-blueBlack"
                    htmlFor={props.id || props.name}
                >
                    {label}
                </label>
                <div className="px-2 input-group flex flex-row items-center justify-between rounded-md bg-blue-xlblue bg-opacity-30 border border-blue-main border-opacity-25 text-blue-main font-medium">
                    <input
                        onChange={props.onChange}
                        value={props.value}
                        name={props.name}
                        type={props.type}
                        placeholder={props.placeholder}
                        className="form-input border-0 bg-transparent p-2 w-1/2 flex-shrink flex-grow"
                        {...field}
                        {...props}
                    />

                    <select
                        onChange={props.selectonchange}
                        defaultValue={props.selectdefaultvalue}
                        className={`${
                            props.head ? "block" : "hidden"
                        } text-blueGray-400 bg-transparent rounded border-blue-borderColor shadow-inner m-2 `}
                    >
                        {props.head.map(s => (
                            <option key={s.val} value={s.val}>
                                {s.opt}
                            </option>
                        ))}
                    </select>
                </div>
                {meta.touched && meta.error ? (
                    <div className="error text-red-500 mt-1 flex flex-row space-x-2 text-xs2">
                        <ExclamationIcon className="w-4" />
                        <span className="">{meta.error}</span>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default function CalculatorForm() {
    const [displayResult, setDisplayResult] = useState(false)
    const [hashRate, setHashRate] = useState(50)
    const [hashRateType, setHashRateType] = useState("terahash")
    const [powerConsumption, setPowerConsumption] = useState(3600)
    const [powerConsumptionType, setPowerConsumptionType] = useState("watt")
    const [powerCost, setPowerCost] = useState(0.075)
    const [poolFees, setPoolFees] = useState(2)
    const [blockReward, setBlockReward] = useState()
    const [difficultyRate, setDifficultyRate] = useState("")
    const [exchangeRate, setExchangeRate] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false)
    const [calculationData, setCalculationData] = useState([])
    const [costPerTeraHash, setCostPerTerahash] = useState(90)
    const [budget, setBudget] = useState(10000)
    const [totalAsics, setTotalAsics] = useState(2)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    useEffect(() => {
        setButtonDisabled(buttonLoading === true ? true : false)
    }, [buttonLoading])

    useRef(() => {
        onchange.bind(this)
        calculate.bind(this)
        onHashRateChange.bind(this)
        onPowerConsumptionChange.bind(this)
    }, [])

    useEffect(() => {
        defaultMining()
        // eslint-disable-next-line
    }, [])

    const defaultMining = async () => {
        const api = new Api()
        let difficultyRateApi = await api.get("q/getdifficulty")
        let blockRewardApi = await api.get("q/bcperblock")
        let exchageRateApi = await api.get("ticker")

        if (difficultyRateApi.status === 200) {
            setDifficultyRate(difficultyRateApi.data)
        }
        if (blockRewardApi.status === 200) {
            setBlockReward(blockRewardApi.data)
        }
        if (exchageRateApi.status === 200) {
            setExchangeRate(exchageRateApi.data.USD.buy)
        }
    }

    const correctValue = number => {
        if (number) {
            var roundedNumber
            roundedNumber = Math.round(1000 * number) / 1000
            if (roundedNumber > 0) return roundedNumber
            roundedNumber = Math.round(10000 * number) / 10000
            if (roundedNumber > 0) return roundedNumber
            roundedNumber = Math.round(100000 * number) / 100000
            if (roundedNumber > 0) return roundedNumber
            roundedNumber = Math.round(1000000 * number) / 1000000
            if (roundedNumber > 0) return roundedNumber
            var numberArray = String(number).split("e-")
            if (numberArray.length > 1) {
                var toFixedNum = parseInt(numberArray[1]) + 3
                return number.toFixed(toFixedNum)
            } else {
                return number
            }
        }
    }

    const hashrateConverter = (
        type,
        newHashRateType = "",
        value,
        flag = "display",
    ) => {
        let hashValue = 0
        let kilohashvalue = 0
        let megahashvalue = 0
        let gigahashvalue = 0
        let terahashvalue = 0
        let petahashvalue = 0
        let exahashvalue = 0
        if (type === "hash") {
            hashValue = correctValue(value)
            kilohashvalue = correctValue(value / 1000)
            megahashvalue = correctValue(value / 1000000)
            gigahashvalue = correctValue(value / 1000000000)
            terahashvalue = correctValue(value / 1000000000000)
            petahashvalue = correctValue(value / 1000000000000000)
            exahashvalue = correctValue(value / 1000000000000000000)
        } else if (type === "kilohash") {
            hashValue = correctValue(value * 1000)
            kilohashvalue = correctValue(value)
            megahashvalue = correctValue(value / 1000)
            gigahashvalue = correctValue(value / 1000000)
            terahashvalue = correctValue(value / 1000000000)
            petahashvalue = correctValue(value / 1000000000000)
            exahashvalue = correctValue(value / 1000000000000000)
        } else if (type === "megahash") {
            hashValue = correctValue(value * 1000000)
            kilohashvalue = correctValue(value * 1000)
            megahashvalue = correctValue(value)
            gigahashvalue = correctValue(value / 1000)
            terahashvalue = correctValue(value / 1000000)
            petahashvalue = correctValue(value / 1000000000)
            exahashvalue = correctValue(value / 1000000000000)
        } else if (type === "gigahash") {
            hashValue = correctValue(value * 1000000000)
            kilohashvalue = correctValue(value * 1000000)
            megahashvalue = correctValue(value * 1000)
            gigahashvalue = correctValue(value)
            terahashvalue = correctValue(value / 1000)
            petahashvalue = correctValue(value / 1000000)
            exahashvalue = correctValue(value / 1000000000)
        } else if (type === "terahash") {
            hashValue = correctValue(value * 1000000000000)
            kilohashvalue = correctValue(value * 1000000000)
            megahashvalue = correctValue(value * 1000000)
            gigahashvalue = correctValue(value * 1000)
            terahashvalue = correctValue(value)
            petahashvalue = correctValue(value / 1000)
            exahashvalue = correctValue(value / 1000000)
        } else if (type === "petahash") {
            hashValue = correctValue(value * 1000000000000000)
            kilohashvalue = correctValue(value * 1000000000000)
            megahashvalue = correctValue(value * 1000000000)
            gigahashvalue = correctValue(value * 1000000)
            terahashvalue = correctValue(value * 1000)
            petahashvalue = correctValue(value)
            exahashvalue = correctValue(value / 1000)
        } else if (type === "exahash") {
            hashValue = correctValue(value * 1000000000000000000)
            kilohashvalue = correctValue(value * 1000000000000000)
            megahashvalue = correctValue(value * 1000000000000)
            gigahashvalue = correctValue(value * 1000000000)
            terahashvalue = correctValue(value * 1000000)
            petahashvalue = correctValue(value * 1000)
            exahashvalue = correctValue(value)
        }
        if (flag === "display") {
            return newHashRateType === "hash"
                ? hashValue
                : newHashRateType === "kilohash"
                ? kilohashvalue
                : newHashRateType === "megahash"
                ? megahashvalue
                : newHashRateType === "gigahash"
                ? gigahashvalue
                : newHashRateType === "terahash"
                ? terahashvalue
                : newHashRateType === "petahash"
                ? petahashvalue
                : exahashvalue
        }
        return hashValue
    }

    const initHashrateConverter = (type, value) => {
        let terahashvalue = 0
        if (type === "hash") {
            terahashvalue = initCorrectValue(value / 1000000000000)
        } else if (type === "kilohash") {
            terahashvalue = initCorrectValue(value / 1000000000)
        } else if (type === "megahash") {
            terahashvalue = initCorrectValue(value / 1000000)
        } else if (type === "gigahash") {
            terahashvalue = initCorrectValue(value / 1000)
        } else if (type === "terahash") {
            terahashvalue = initCorrectValue(value)
        } else if (type === "petahash") {
            terahashvalue = initCorrectValue(value * 1000)
        } else if (type === "exahash") {
            terahashvalue = initCorrectValue(value * 1000000)
        }
        return terahashvalue
    }

    const initCorrectValue = number => {
        if (number) {
            var roundedNumber
            roundedNumber = Math.round(1000 * number) / 1000
            if (roundedNumber > 0) return roundedNumber
            roundedNumber = Math.round(10000 * number) / 10000
            if (roundedNumber > 0) return roundedNumber
            roundedNumber = Math.round(100000 * number) / 100000
            if (roundedNumber > 0) return roundedNumber
            roundedNumber = Math.round(1000000 * number) / 1000000
            if (roundedNumber > 0) return roundedNumber
            var numberArray = String(number).split("e-")
            if (numberArray.length > 1) {
                var toFixedNum = parseInt(numberArray[1]) + 3
                return number.toFixed(toFixedNum)
            } else {
                return number
            }
        }
    }

    const powerConsumptionConverter = (
        type,
        newPowerConverterType,
        value,
        flag = "display",
    ) => {
        let wattValue = 0
        let kilowattValue = 0
        let megawattValue = 0
        let gigawattValue = 0
        let terawattValue = 0
        if (type === "watt") {
            wattValue = correctValue(value)
            kilowattValue = correctValue(value / 1000)
            megawattValue = correctValue(value / 1000000)
            gigawattValue = correctValue(value / 1000000000)
            terawattValue = correctValue(value / 1000000000000)
        } else if (type === "kilowatt") {
            wattValue = correctValue(value * 1000)
            kilowattValue = correctValue(value)
            megawattValue = correctValue(value / 1000)
            gigawattValue = correctValue(value / 1000000)
            terawattValue = correctValue(value / 1000000000)
        } else if (type === "megawatt") {
            wattValue = correctValue(value * 1000000)
            kilowattValue = correctValue(value * 1000)
            megawattValue = correctValue(value)
            gigawattValue = correctValue(value / 1000)
            terawattValue = correctValue(value / 1000000)
        } else if (type === "gigawatt") {
            wattValue = correctValue(value * 1000000000)
            kilowattValue = correctValue(value * 1000000)
            megawattValue = correctValue(value * 1000)
            gigawattValue = correctValue(value)
            terawattValue = correctValue(value / 1000)
        } else if (type === "terawatt") {
            wattValue = correctValue(value * 1000000000000)
            kilowattValue = correctValue(value * 1000000000)
            megawattValue = correctValue(value * 1000000)
            gigawattValue = correctValue(value * 1000)
            terawattValue = correctValue(value)
        }
        if (flag === "display") {
            return newPowerConverterType === "watt"
                ? wattValue
                : newPowerConverterType === "kilowatt"
                ? kilowattValue
                : newPowerConverterType === "megawatt"
                ? megawattValue
                : newPowerConverterType === "gigawatt"
                ? gigawattValue
                : newPowerConverterType === "terawatt"
                ? terawattValue
                : ""
        }
        return wattValue
    }

    const calculate = async () => {
        if (
            budget &&
            hashRate &&
            powerConsumption &&
            powerCost &&
            poolFees &&
            exchangeRate &&
            costPerTeraHash
        ) {
            setButtonLoading(true)

            const api = new Api()

            const calculatedHashRate = hashrateConverter(
                hashRateType,
                "",
                hashRate,
                "server",
            )
            const calculatePowerConsumption = powerConsumptionConverter(
                powerConsumptionType,
                "",
                powerConsumption,
                "server",
            )

            let response = await api.create("user/mining/calculate", {
                hashRate: Number(calculatedHashRate),
                powerConsumption: Number(calculatePowerConsumption),
                powerCost: Number(powerCost),
                blockReward: Number(blockReward),
                difficultyRate: difficultyRate,
                exchangeRate: exchangeRate,
                poolFees: Number(poolFees),
                budget: Number(budget),
                costPerTeraHash: Number(costPerTeraHash),
            })
            response = response.data
            if (response.code === 200) {
                // console.log(response.data);
                setButtonLoading(false)
                setCalculationData(response.data)
                // console.log(`The content is: ${calculationData[0].poolFeesUsd}`);
            }
        } else {
            let errorMsg = "Please enter valid details for required fields."
            if (!budget) {
                errorMsg = "Please enter valid budget."
            } else if (!costPerTeraHash) {
                errorMsg = "Please enter valid cost per terahash."
            } else if (!hashRate) {
                errorMsg = "Please enter valid hash rate."
            } else if (!powerConsumption) {
                errorMsg = "Please enter valid power consumption."
            } else if (!powerCost) {
                errorMsg = "Please enter valid power cost."
            } else if (!poolFees) {
                errorMsg = "Please enter valid pool fees."
            } else if (!blockReward) {
                errorMsg = "Please enter valid block reward."
            } else if (!exchangeRate) {
                errorMsg = "Please enter valid exchange rate."
            }
            toast.error(errorMsg)
        }
    }

    const onchange = event => {
        let inputName = event.target.name
        let inputValue = event.target.value

        if (inputName === "budget") {
            setBudget(inputValue)
        }

        if (inputName === "costPerTera") {
            setCostPerTerahash(inputValue)
        }

        if (inputName === "totalAsics") {
            // eslint-disable-next-line
            let teraHashConverter = initHashrateConverter(
                hashRateType,
                hashRate,
            )
            // let totalAsics = inputValue / (costPerTeraHash * teraHashConverter);
            // totalAsics = Math.round(totalAsics);
            // console.log(totalAsics);
            setTotalAsics(inputValue)
        }

        if (inputName === "hashRatePerAsic") {
            setHashRate(inputValue)
        }

        if (inputName === "powerConsumption") {
            setPowerConsumption(inputValue)
        }

        if (inputName === "powerCost") {
            setPowerCost(inputValue)
        }

        if (inputName === "poolFees") {
            setPoolFees(inputValue)
        }

        if (inputName === "blockReward") {
            setBlockReward(inputValue)
        }

        if (inputName === "exchangeRate") {
            setExchangeRate(inputValue)
        }
    }

    const onHashRateChange = event => {
        let { value } = event.target

        const hashrateDisplay = hashrateConverter(hashRateType, value, hashRate)
        setHashRateType(value)
        setHashRate(hashrateDisplay)
    }

    const onPowerConsumptionChange = event => {
        let { value } = event.target

        const wattDisplay = powerConsumptionConverter(
            powerConsumptionType,
            value,
            powerConsumption,
        )
        setPowerConsumptionType(value)
        setPowerConsumption(wattDisplay)
    }

    // const decimalValue = (value, digit) => {
    //   const decimal = value.toString().split(".");
    //   const result = decimal[0] + "." + decimal[1].slice(0, digit);
    //   return result;
    // };

    // const results = () => {
    //   let calculationRequestsHtml = "";
    //   if (calculationData && !_.isEmpty(calculationData)) {
    //     calculationRequestsHtml = calculationData.map((details) => (
    //       <tr key={Math.random()}>
    //         <td>{details.label}</td>
    //         <td>{details.btcMined}</td>
    //         <td>&#36;{niceNumberDecimalDisplay(details.btcMinedUsd, 2)}</td>
    //         <td>{details.poolFeesBtc}</td>
    //         <td>&#36;{niceNumberDecimalDisplay(details.poolFeesUsd, 2)}</td>
    //         <td>
    //           &#36;{niceNumberDecimalDisplay(details.electricityCostUsd, 0)}
    //         </td>
    //         <td>&#36;{niceNumberDecimalDisplay(details.profit, 2)}</td>
    //       </tr>
    //     ));
    //   } else if (!buttonLoading && _.isEmpty(calculationData)) {
    //     calculationRequestsHtml = (
    //       <tr className="text-center" key={Math.random()}>
    //         <td colSpan="7">
    //           {/* <h3 className="text-default-color text-xl">
    //             {messageConstants.NO_RECORDS_FOUND}
    //           </h3> */}
    //         </td>
    //       </tr>
    //     );
    //   }

    //   let totalhashrateforuser = budget / costPerTeraHash;
    //   totalhashrateforuser = niceNumberDecimalDisplay(totalhashrateforuser, 2);
    // };

    if (!displayResult) {
        return (
            <>
                <Formik
                    initialValues={{
                        budget: { budget },
                        costPerTera: { costPerTeraHash },
                        totalAsics: { totalAsics },
                        hashRatePerAsic: { hashRateType },
                        powerConsumption: {
                            powerConsumption,
                        },
                        powerCost: { powerCost },
                        poolFees: { poolFees },
                        blockReward: { blockReward },
                        difficultyRate: { difficultyRate },
                        exchangeRate: { exchangeRate },
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        await new Promise(r => setTimeout(r, 500))
                        calculate()
                        setDisplayResult(true)
                        setSubmitting(false)
                    }}
                    validator={() => ({})}
                >
                    <Form
                        className="box-content xl:mx-12 mb-12 px-12 rounded-sm border border-dashed border-blue-main border-opacity-25 bg-white p-10 grid xl:grid-cols-4 justify-items-stretch xl:gap-x-6 gap-y-10 "
                        method="POST"
                    >
                        <MyTextInput
                            label="Budget"
                            name="budget"
                            type="number"
                            min="0"
                            head="USD"
                            onChange={onchange}
                            value={budget}
                        />
                        <MyTextInput
                            label="Cost per Terahash"
                            name="costPerTera"
                            type="text"
                            head="$/Th"
                            onChange={onchange}
                            value={costPerTeraHash}
                        />
                        {/* <MyTextInput
              label="Total ASICs"
              name="totalAsics"
              type="text"
              onChange={onchange}
              value={totalAsics}
            /> */}

                        <MyTextInputWithSelect
                            label="Hashrate per ASIC"
                            name="hashRatePerAsic"
                            type="text"
                            head={hashrateOptions}
                            onChange={onchange}
                            value={hashRate}
                            selectonchange={onHashRateChange}
                            selectdefaultvalue={hashRateType}
                        />
                        <MyTextInputWithSelect
                            label="Power Consumption"
                            name="powerConsumption"
                            type="text"
                            head={powerConsumptionOptions}
                            onChange={onchange}
                            value={powerConsumption}
                            selectonchange={onPowerConsumptionChange}
                            selectdefaultvalue={powerConsumptionType}
                        />
                        <MyTextInput
                            label="Power Cost"
                            name="powerCost"
                            type="text"
                            step="0.001"
                            head="USD/kWh"
                            onChange={onchange}
                            value={powerCost}
                        />
                        <MyTextInput
                            label="Pool Fees"
                            name="poolFees"
                            type="text"
                            head="%"
                            onChange={onchange}
                            value={poolFees}
                        />
                        <MyTextInput
                            label="Block Reward"
                            name="blockReward"
                            type="text"
                            head="BTC"
                            onChange={onchange}
                            value={blockReward}
                            readOnly
                        />
                        <MyTextInput
                            label="Difficulty Rate"
                            name="difficultyRate"
                            type="text"
                            head="G"
                            value={difficultyRate}
                            readOnly
                        />
                        <MyTextInput
                            label="Exchange Rate"
                            name="exchangeRate"
                            type="text"
                            head="USD/BTC"
                            onChange={onchange}
                            value={exchangeRate}
                            readOnly
                        />

                        <Button
                            type="submit"
                            disabled={buttonDisabled}
                            text="calculate"
                            mode="primary"
                            classes="col-span-4 w-full"
                        />
                    </Form>
                </Formik>
            </>
        )
    } else {
        return (
            <div className="overflow-x-auto flex flex-col pb-8 lg:mx-12 space-y-8 items-center border border-dashed border-blue-main border-opacity-20 bg-white md:p-12">
                <div className="overflow-x-auto bg-white rounded flex-shrink">
                    <Heading1
                        text="Profit Details"
                        classes="text-center py-4"
                    />
                    <div className="overflow-x-auto py-2 align-middle sm:px-6 lg:px-2">
                        <div className="overflow-x-auto shadow-none lg:shadow border-b border-blueGray-200 sm:rounded-lg">
                            <div className="flex flex-col w-screen space-y-12 lg:hidden">
                                {calculationData.map(result => (
                                    <ul className="shadow">
                                        <h1 className="px-6 py-4 text-center whitespace-nowrap text-md text-blueGray-500 opacity-40">
                                            {result.label}
                                        </h1>
                                        <li className="px-6 py-4 text-center whitespace-nowrap text-xs2 font-medium text-blue-main">
                                            <code className="text-blue-semilight">
                                                BTC Mined:
                                            </code>{" "}
                                            {result.btcMined}
                                        </li>
                                        <li className="px-6 py-4 text-center whitespace-nowrap text-xs2 font-medium text-blue-main">
                                            <code className="text-blue-semilight">
                                                BTC Mined(USD):
                                            </code>{" "}
                                            ${Math.round(result.btcMinedUsd)}
                                        </li>
                                        <li className="px-6 py-4 text-center whitespace-nowrap text-xs2 font-medium text-blue-main">
                                            <code className="text-blue-semilight">
                                                Pool Fees:
                                            </code>{" "}
                                            {result.poolFeesBtc}
                                        </li>
                                        <li className="px-6 py-4 text-center whitespace-nowrap text-xs2 font-medium text-blue-main">
                                            <code className="text-blue-semilight">
                                                Pool Fees(USD):
                                            </code>{" "}
                                            ${Math.round(result.poolFeesUsd)}
                                        </li>
                                        <li className="px-6 py-4 text-center whitespace-nowrap text-xs2 font-medium text-blue-main">
                                            <code className="text-blue-semilight">
                                                Electricy Costs:
                                            </code>{" "}
                                            $
                                            {Math.round(
                                                result.electricityCostUsd,
                                            )}
                                        </li>
                                        <li className="px-6 py-4 w-full text-center whitespace-nowrap text-xs2 font-medium text-white bg-blue-main">
                                            <code className="font-light">
                                                Profit:
                                            </code>{" "}
                                            ${Math.round(result.profit)}
                                        </li>
                                    </ul>
                                ))}
                            </div>

                            <table className="hidden lg:block divide-y divide-blueGray-200 ">
                                <thead className="bg-blue-xlblue bg-opacity-60 ">
                                    <tr className="">
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs2 font-semibold opacity-80 text-blueBlack "
                                        >
                                            Period
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs2 font-semibold opacity-80 text-blueBlack "
                                        >
                                            BTC Mined (BTC)
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs2 font-semibold opacity-80 text-blueBlack "
                                        >
                                            BTC Mined (USD)
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs2 font-semibold opacity-80 text-blueBlack "
                                        >
                                            Pool Fees (BTC)
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs2 font-semibold opacity-80 text-blueBlack "
                                        >
                                            Pool Fees (USD)
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs2 font-semibold opacity-80 text-blueBlack "
                                        >
                                            Electricity Costs (USD)
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs2 font-medium text-white bg-blue-main text-center"
                                        >
                                            Profit
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-blueGray-200">
                                    {calculationData.map(result => (
                                        <tr>
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-md text-blueGray-500 opacity-40">
                                                {result.label}
                                            </td>
                                            <td className="px-6 py-4 text-left whitespace-nowrap text-xs2 font-medium text-blue-main">
                                                {result.btcMined}
                                            </td>
                                            <td className="px-6 py-4 text-center whitespace-nowrap text-xs2 font-medium text-blue-main">
                                                $
                                                {Math.round(result.btcMinedUsd)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-xs2 font-medium text-blue-main">
                                                {result.poolFeesBtc}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-center text-xs2 font-medium text-blue-main">
                                                $
                                                {Math.round(result.poolFeesUsd)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-center text-xs2 font-medium text-blue-main">
                                                $
                                                {Math.round(
                                                    result.electricityCostUsd,
                                                )}
                                            </td>
                                            <td className="px-12 py-4 whitespace-nowrap text-center text-xs2 font-medium text-white bg-blue-main">
                                                ${Math.round(result.profit)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <code className="text-sm leading-7 w-1/2 md:w-8/12 xl:w-auto">
                    <span className="text-blue-main font-bold">
                        Disclosure:{" "}
                    </span>
                    The Profit Details are the result of a specific budget input
                    to purchase bitcoin mining servers (ASICs) with a specific
                    hashrate performance. The Profit Details also assume that
                    the current bitcoin network difficulty rate remains
                    constant. The Profit Details are also subject to the BTC/USD
                    exchange rate input in the calculator. These figures vary
                    based on the total network hash rate and on the BTC/USD
                    conversion rate. Block reward is fixed at 6.25 BTC. The
                    Profit Details do not take into account any future changes
                    in block rewards, hashrate and difficulty rate. All inputs
                    in the calculator will vary over time. The profit details
                    are based on input values only and should be used solely as
                    a guide.
                </code>
                <Button
                    // TODO fix go back button functionality
                    onClick={async () => {
                        await new Promise(r => setTimeout(r, 500))
                        setDisplayResult(false)
                    }}
                    text="go back"
                    mode="secondary"
                    className="md:mb-8"
                />
            </div>
        )
    }
}

const powerConsumptionOptions = [
    { opt: "W", val: "watt" },
    { opt: "kW", val: "kilowatt" },
    { opt: "MW", val: "megawatt" },
    { opt: "GW", val: "gigawatt" },
    { opt: "TW", val: "terawatt" },
]

const hashrateOptions = [
    { opt: "H/s", val: "hash" },
    { opt: "kH/s", val: "kilohash" },
    { opt: "MH/s", val: "megahash" },
    { opt: "GH/s", val: "gigahash" },
    { opt: "TH/s", val: "terahash" },
    { opt: "PH/s", val: "petahash" },
    { opt: "EH/s", val: "exahash" },
]
