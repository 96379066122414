import React from "react"

import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

import { ReactComponent as OverviewDivider } from "../../../images/overview-divider.svg"

export default function Overview() {
    return (
        <div className="pt-24 flex flex-col items-center space-y-12">
            <div className="">
                <Heading6
                    text="OVERVIEW"
                    classes="text-blue-main text-center uppercase my-4"
                />
                <Heading2
                    text="Making natural gas cleaner"
                    classes="text-center text-xl lg:text-xl2"
                />
                <Paragraph
                    text="Providing power to the blockchain and enhancing the circular economy"
                    classes="text-center opacity-60 pt-4 whitespace-pre-wrap text-md"
                />
            </div>
            <OverviewDivider />

            <div className="grid xl:grid-cols-2 gap-y-12 justify-items-center gap-x-12">
                <div className="flex flex-col w-3/4  sm:w-96 space-y-12 items-center bg-blue-mdsemilight p-2 sm:p-12 border border-blue-borderColor rounded-tl-large rounded-bl-sm border-opacity-50">
                    <Heading2
                        text="The Challenge"
                        classes="text-md3 text-center sm:text-left border-b-2 border-blue-lightblue p-10"
                    />
                    <Paragraph
                        text="US$900 billion stranded energy assets and US$16 billion natural gas wasted each year"
                        classes="text-md2 sm:text-md3 text-center sm:text-left opacity-60"
                    />
                    <Paragraph
                        text="Digital-asset mining demands increasing power"
                        classes="text-md2 sm:text-md3 text-center sm:text-left opacity-60"
                    />
                </div>
                <div className="flex flex-col w-3/4 sm:w-96 space-y-12 items-center bg-blue-xlblue bg-opacity-60 p-2 sm:p-12 border border-blue-borderColor rounded-tr-large rounded-br-sm ">
                    <Heading2
                        text="The Solution"
                        classes="text-md3 text-center sm:text-left border-b-2 border-blue-main p-10"
                    />
                    <Paragraph
                        text="PermianChain works with third party operators to register and tokenize natural gas resources that has been converted into affordable power (kWh) to supply electricity to data centres on-site."
                        classes="text-md2 sm:text-md3 text-center sm:text-left  opacity-60"
                    />
                </div>
            </div>
        </div>
    )
}
