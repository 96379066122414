import React from "react"
import axios from "axios"
import qs from "qs"

export default class Api extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            miningResults: [],
        }
    }

    get(url) {
        const baseUrl = "https://blockchain.info"
        const response = axios.get(`${baseUrl}/${url}`)
        return response
    }

    create(url, params) {
        const baseUrl = "https://masterapi.permianchain.com"
        console.log(params)
        const response = axios({
            method: "post",
            url: `${baseUrl}/${url}`,
            data: qs.stringify({
                hashRate: params.hashRate,
                powerConsumption: params.powerConsumption,
                powerCost: params.powerCost,
                blockReward: params.blockReward,
                difficultyRate: params.difficultyRate,
                exchangeRate: params.exchangeRate,
                poolFees: params.poolFees,
                budget: params.budget,
                costPerTeraHash: params.costPerTeraHash,
            }),
            headers: {
                "content-type":
                    "application/x-www-form-urlencoded;charset=utf-8",
            },
        })

        return response
    }
}
