import React from "react"

import { Heading2, Heading6 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"

import Blog1 from "../../../../images/blog/blog1.jpg"

export default function BlogCard({
    fullWidth,
    articleImg,
    smallTitle,
    title,
    articleText,
    id,
}) {
    //   if (fullWidth === 1) {
    //     return (
    //       <div className="flex flex-col w-full">
    //         <div
    //           className={`bg-${articleImg} bg-no-repeat bg-center bg-cover`}
    //         ></div>
    //         <Heading6
    //           text={smallTitle}
    //           classes="text-blue-main text-left uppercase my-4"
    //         />
    //         <Heading1 text={title} classes="text-left text-lg lg:text-xxxl" />
    //         <Paragraph
    //           text={articleText}
    //           classes="text-left opacity-60 pt-4 whitespace-pre-wrap"
    //         />
    //       </div>
    //     );
    //   }
    return (
        <div className="flex flex-col  bg-gray-lightGray bg-opacity-50 mb-24">
            <div className={`rounded-t-lg `}>
                <img
                    src={articleImg || Blog1}
                    alt="blog-img"
                    className="w-full h-60"
                />
            </div>
            <div className="p-4 border border-t-0 border-dashed  border-blue-borderColor h-48">
                <Heading6
                    text={smallTitle}
                    classes="text-blue-main text-left uppercase my-4 text-xs"
                />
                <a href={`/blog/${id}`} rel="noreferrer">
                    <Heading2
                        text={title}
                        classes="text-left text-sm2 lg:text-md2"
                    />
                </a>
                <Paragraph
                    text={articleText}
                    classes="text-left opacity-60 pt-4 whitespace-pre-wrap text-sm"
                />
            </div>
        </div>
    )
}
