import React from "react"

import InvestorLogo2 from "../../../../images/PermianInvestor.png"

import { Link } from "../../../elements/Button"
import { Heading2 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"
import ProductFeature from "./ProductFeature"

import MarketIcon from "../../../../images/icons/market.png"
import MoneyUpIcon from "../../../../images/icons/moneyup.png"

function Investor({ illustration }) {
    return (
        <>
            <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center items-center pt-12 pb-14 pl-0 xl:pl-20">
                <div className="flex flex-col space-y-8 ">
                    <img
                        src={InvestorLogo2}
                        alt="Miner Logo"
                        className="mx-auto lg:mx-0 w-2/6 mb-5"
                    />
                    <Heading2
                        text="Digital Securities issuance
            platform for exploration & production companies"
                        classes="text-md3 text-center lg:text-left"
                    />

                    <Paragraph
                        classes="opacity-60 text-center lg:text-left text-sm2"
                        text="Making it simple for exploration & production (E&P) companies and registered market dealers to work together to achieve sustainable resource financing
            "
                    />

                    <Paragraph
                        classes="opacity-80 text-center lg:text-left text-sm2 font-semibold "
                        text="The PermianChain platform uses the blockchain to enable exploration
            and production (E&P) companies to catalogue, manage and create
            value from their proven oil and gas reserves.
            "
                    />

                    <Link
                        classes="mx-auto lg:mx-0 w-52 text-center"
                        text="get started"
                        mode="primary"
                        href="https://investor.permianchain.com"
                    />
                </div>
                <img src={illustration} className="w-9/12" alt="illustration" />
            </div>
            <div className="flex flex-col xl:flex-row pl-0 xl:pl-20 pb-14 space-x-0 xl:space-x-14 space-y-14 xl:space-y-0 ">
                <ProductFeature
                    icon={MarketIcon}
                    headingText="Enhancing the way exempt
          market dealers raise capital
          "
                    pText="PermianChain Investor platform enables financial services companies to sponsor and offer debt and equity investment opportunities as compliant digital securities in the form of smart contacts on the blockchain to accredited investors under available prospectus exemptions."
                />
                <ProductFeature
                    icon={MoneyUpIcon}
                    headingText="Bringing efficiency and
          transparency through the blockchain
          "
                    pText="PermianChain establishes an open, secure, transparent and direct private investment platform. The digital infrastructure which PermianChain is built on allows the overall efficiency and transparency of the investment process.

          "
                />
            </div>
        </>
    )
}

export default Investor
