import React from "react"

// import { ReactComponent as SupplierLogo } from "../../../../images/products/supplier.svg";
import MinerLogo2 from "../../../../images/PermianMiner.png"

import { Link } from "../../../elements/Button"
import { Heading2 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"
import ProductFeature from "./ProductFeature"

import MarketIcon from "../../../../images/icons/market.png"
import MoneyUpIcon from "../../../../images/icons/moneyup.png"
import MoneyBagsIcon from "../../../../images/icons/moneybags.png"

function Miner({ illustration }) {
    return (
        <>
            <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center items-center pt-12 pb-14 pl-0 xl:pl-20">
                <div className="flex flex-col space-y-8 ">
                    <img
                        src={MinerLogo2}
                        alt="Miner Logo"
                        className="mx-auto lg:mx-0 w-2/6 mb-5"
                    />
                    <Heading2
                        text="Enabling data mining companies
            access to natural gas fields for a
            more efficient energy source."
                        classes="text-md3 text-center lg:text-left"
                    />

                    <Paragraph
                        classes="opacity-60 text-center lg:text-left text-sm2"
                        text="With an integrated digital marketplace making it simple for natural gas operators and data mining companies to digitally trade field-generated electricity to power onsite server farms."
                    />

                    <Link
                        classes="mx-auto lg:mx-0 w-52 text-center"
                        text="get started"
                        mode="primary"
                        href="https://miner.permianchain.com"
                    />
                </div>
                <img src={illustration} className="" alt="illustration" />
            </div>
            <div className="flex flex-col xl:flex-row pl-0 xl:pl-20 pb-14 space-x-0 xl:space-x-14 space-y-14 xl:space-y-0 ">
                <ProductFeature
                    icon={MarketIcon}
                    headingText="Affordable Electricity"
                    pText="PermianChain Miner connects data miners to consistent, low-cost power"
                />
                <ProductFeature
                    icon={MoneyUpIcon}
                    headingText="Efficient Mining Management"
                    pText="PermianChain Miner is an integrated solution for digital asset mining"
                />
                <ProductFeature
                    icon={MoneyBagsIcon}
                    headingText="Tokenized Marketplace"
                    pText="PermianChain Miner lets data miners and energy companies trade tokenized power"
                />
            </div>
        </>
    )
}

export default Miner
