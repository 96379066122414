import React from "react"

// import Circle from "../../../../images/wwd-circles.png";
// import Rect from "../../../../images/wwd-rects.png";
// import RectCircle from "../../../../images/rect-circle.png";

// import Paragraph from "../../../elements/Paragraph";

export default function WhatWeDoCard({
    className,
    //   invertOrder,
    //   content,
    cardImage,
    mobileCardImage,
}) {
    //   if (invertOrder) {
    //     return (
    //       <div className={`${className} flex flex-row `}>
    //         {/* <img src={Rect} alt="circle"></img> */}
    //         <div className="relative  bg-white border border-dashed border-blue-borderColor rounded-sm flex flex-row">
    //           <div className="flex flex-col">
    //             {content.texts.map((text) => (
    //               <li>{text}</li>
    //             ))}
    //           </div>
    //           <div className="flex flex-col">
    //             <Paragraph text={content.texts[1]} />
    //             <Paragraph text={content.texts[1]} />
    //           </div>
    //           <div className="flex flex-col">
    //             <Paragraph text={content.texts[2]} />
    //             <Paragraph text={content.texts[2]} />
    //           </div>
    //         </div>
    //         <img src={Circle} className="z-10" alt="circle" />
    //       </div>
    //     );
    //   }

    return (
        <div className={`${className} flex flex-row `}>
            <img
                draggable="false"
                style={{ userDrag: "none" }}
                srcSet={`${mobileCardImage} 761w,
             ${cardImage} 1172w`}
                sizes="(max-width: 600px) 761px,
            1172px"
                src={cardImage}
                className="select-none z-10"
                alt="circle"
            />
            {/* <img src={Rect} alt="circle"></img> */}
        </div>
    )
}
