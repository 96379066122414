import React, { useEffect, useState, useCallback } from "react"

import { Heading5 } from "../components/elements/Headings"
import Footer from "../components/layout/Footer"
import { getBlogs } from "../services/BlogApi"
import { format } from "date-fns"
import parse from "html-react-parser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarPlus } from "@fortawesome/free-regular-svg-icons"

let plainText = ""

const options = {
    replace: domNode => {
        if (domNode.name) {
            const children = domNode.children
            if (children[0]) {
                plainText += children[0].data
            }
        }
        return plainText
    },
}

function Blog() {
    const [blogs, setBlogs] = useState([])

    const getBlogsFrpmAPI = useCallback(async () => {
        try {
            const res = await getBlogs()
            setBlogs(
                res.data.data.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                ),
            )
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        getBlogsFrpmAPI()
    }, [getBlogsFrpmAPI])

    return (
        <>
            <div className="grid grid-cols-1 justify-around items-center gap-x-12">
                <div className="flex flex-col  sm:flex-row items-center bg-gray-lightGray mt-24 lg:mt-36 mx-6 lg:mx-48" />
                {blogs.map(b => {
                    plainText = ""
                    parse(b.content, options)
                    return (
                        <div
                            key={b._id}
                            className="flex flex-col mt-12 mx-6 lg:mx-48 p-6"
                        >
                            <div className="font-bold uppercase text-left text-xl">
                                <a href={`/blog/${b.slug}`} rel="noreferrer">
                                    {b.title}
                                </a>
                            </div>
                            <div className="mb-6 flex flex-row items-center">
                                <FontAwesomeIcon
                                    icon={faCalendarPlus}
                                    size="1x"
                                    color="grey"
                                />
                                <Heading5
                                    text={format(
                                        new Date(b.createdAt),
                                        "dd MMMM yyyy",
                                    )}
                                    classes="text-left text-sm my-2 ml-3"
                                />
                            </div>
                            <div classes="text-left text-justify opacity-60 text-sm italic">
                                {`${plainText.slice(0, 400)}...`}
                            </div>

                            <a
                                className="text-red-400 underline my-4 italic"
                                href={`/blog/${b.slug}`}
                                rel="noreferrer"
                            >
                                Read more
                            </a>

                            <div className="border-b-2 border-red-100 border-opacity-70 flex flex-col mt-12" />
                        </div>
                    )
                })}
            </div>
            <Footer />
        </>
    )
}

export default Blog
