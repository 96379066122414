import React, { useState } from "react"

import Footer from "../components/layout/Footer"
import Button from "../components/elements/Button"
import { addContact } from "../services/ContactApi"
import { Helmet } from "react-helmet"

function Contact() {
    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [firstError, setFristError] = useState("")
    const [lastError, setLastError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [messageError, setMessageError] = useState("")

    const checkFirst = v => {
        if (!v) {
            setFristError("Please enter your first name")
        } else {
            setFristError("")
        }
    }

    const checkLast = v => {
        if (!v) {
            setLastError("Please enter your last name")
        } else {
            setLastError("")
        }
    }

    const checkEmail = v => {
        if (!v) {
            setEmailError("Please enter your email")
        } else {
            setEmailError("")
        }
    }

    const checkMessage = v => {
        if (!v) {
            setMessageError("Please enter your message")
        } else {
            setMessageError("")
        }
    }

    const handleSetFirst = e => {
        setFirst(e.target.value)
        checkFirst(e.target.value)
    }

    const handleSetLast = e => {
        setLast(e.target.value)
        checkLast(e.target.value)
    }

    const handleSetEmail = e => {
        setEmail(e.target.value)
        checkEmail(e.target.value)
    }

    const handleSetMessage = e => {
        setMessage(e.target.value)
        checkMessage(e.target.value)
    }

    const handleSubmit = async () => {
        try {
            checkFirst(first)
            checkLast(last)
            checkEmail(email)
            checkMessage(message)
            if (!firstError && !lastError && !emailError && !messageError) {
                const res = await addContact({
                    firstName: first,
                    lastName: last,
                    email,
                    message,
                })

                if (res.status === 200) {
                    alert("We have received your message!")
                    setFirst("")
                    setLast("")
                    setEmail("")
                    setMessage("")
                } else {
                    alert("Opps, something went wrong.")
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <Helmet>
                <title>
                    Natural Resources Tokenization Platform - PermianChain
                    Technologies Inc.
                </title>
                <meta
                    name="title"
                    property="og:title"
                    content="Natural Resources Tokenization Platform - PermianChain Technologies
                    Inc."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/terms-of-use"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/terms-of-use"
                />
            </Helmet>
            <div className="grid xl:grid-cols-1 gap-y-12 bg-services-at bg-no-repeat bg-center bg-cover items-center justify-items-center py-32">
                <div className="flex flex-col align-items-center items-center bg-at-diamond bg-no-repeat ml-0 xl2:ml-40 px-0 sm:px-32">
                    <div className="text-center font-bold text-xl my-12">
                        Contact Us
                    </div>
                    <div className="w-full max-w-xl my-20 px-4">
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                                    for="grid-first-name"
                                >
                                    First Name
                                </label>
                                <input
                                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                                        firstError
                                            ? "border-red-500"
                                            : "border-grey-700"
                                    } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                    id="grid-first-name"
                                    type="text"
                                    placeholder=""
                                    value={first}
                                    onChange={handleSetFirst}
                                />
                                <p className="text-red-500 text-xs italic">
                                    {firstError}
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                                    for="grid-last-name"
                                >
                                    Last Name
                                </label>
                                <input
                                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                                        lastError
                                            ? "border-red-500"
                                            : "border-grey-700"
                                    } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                    id="grid-last-name"
                                    type="text"
                                    placeholder=""
                                    value={last}
                                    onChange={handleSetLast}
                                />
                                <p className="text-red-500 text-xs italic">
                                    {lastError}
                                </p>
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                                    for="grid-password"
                                >
                                    E-mail
                                </label>
                                <input
                                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                                        emailError
                                            ? "border-red-500"
                                            : "border-grey-700"
                                    } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={handleSetEmail}
                                />
                                <p className="text-red-500 text-xs italic">
                                    {emailError}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                                    for="grid-password"
                                >
                                    Message
                                </label>
                                <textarea
                                    className={`no-resize appearance-none block w-full bg-gray-200 text-gray-700 border ${
                                        messageError
                                            ? "border-red-500"
                                            : "border-grey-700"
                                    } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none`}
                                    id="message"
                                    value={message}
                                    onChange={handleSetMessage}
                                ></textarea>
                                <p className="text-red-500 text-xs italic">
                                    {messageError}
                                </p>
                            </div>
                        </div>
                        <div className="grid justify-items-auto">
                            <Button
                                text="SUBMIT"
                                mode="primary"
                                className="text-center text-white "
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact
