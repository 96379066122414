import React, { useEffect, useState, useCallback } from "react"

import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"
import BlogCard from "./blog/BlogCard"

import { getBlogs } from "../../../services/BlogApi"

export default function BlogSection() {
    const [blogs, setBlogs] = useState([])

    const getBlogsFrpmAPI = useCallback(async () => {
        try {
            const res = await getBlogs()
            const sortedBlogs = res.data.data.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            )
            setBlogs(
                sortedBlogs && sortedBlogs.length > 3
                    ? sortedBlogs.slice(0, 3)
                    : sortedBlogs,
            )
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        getBlogsFrpmAPI()
    }, [getBlogsFrpmAPI])

    return (
        <div className="mt-24 whitespace-pre-wrap flex flex-col items-center space-y-20 rounded-tr-xl">
            <div className="">
                <Heading6
                    text="News"
                    classes="text-blue-main text-center uppercase my-4"
                />
                <Heading2
                    text="Our Blog"
                    classes="text-center text-xl lg:text-xl2"
                />
                <Paragraph
                    text=""
                    classes="text-center opacity-60 pt-4 whitespace-pre-wrap text-md"
                />
            </div>

            <div className="grid md:grid-cols-3 justify-around items-center gap-x-12 m-12">
                {blogs.map(b => {
                    const div = document.createElement("div")
                    div.innerHTML = b.content
                    const firstImage = div.getElementsByTagName("img")[0]
                    const imgSrc = firstImage ? firstImage.src : null
                    return (
                        <BlogCard
                            key={b._id}
                            articleImg={imgSrc}
                            smallTitle=""
                            title={b.title}
                            id={b.slug}
                        />
                    )
                })}
            </div>
        </div>
    )
}
