import React from "react"

import { Heading2, Heading6 } from "../../elements/Headings"

//import HabibImage from "../../../images/team/habib.png"
import MangusImage from "../../../images/team/magnus.png"
import MichelImage from "../../../images/team/michel.png"
import MohamedImage from "../../../images/team/mohamed.png"
//import NazimImage from "../../../images/team/nazim.png"
import PareshImage from "../../../images/team/paresh.png"
import EvonneImage from "../../../images/team/evonne.png"
import MemberCard from "./team/MemberCard"

export default function Team() {
    return (
        <>
            <div className="lg:px-14 pt-24 whitespace-pre-wrap flex flex-col items-center space-y-20">
                <div>
                    <Heading6
                        text="Who we are"
                        classes="text-blue-main text-center uppercase my-4"
                    />
                    <Heading2
                        text="Our Team"
                        classes="text-center text-xl lg:text-xl2"
                    />
                </div>
            </div>

            <div className="mt-12 md:mx-6 md:px-32 xl:mx-12 xl:px-64 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-24 items-start justify-items-center justify-center">
                <MemberCard
                    memberImage={MohamedImage}
                    memberName="Mohamed El-Masri"
                    memberPosition="Founder & Chief Executive Officer"
                />

                <MemberCard
                    memberImage={MichelImage}
                    memberName="Michel Henriquet"
                    memberPosition="Co-Founder"
                />

                <MemberCard
                    memberImage={PareshImage}
                    memberName="Paresh Masani"
                    memberPosition="Chief Technology Officer"
                />

                {/* <MemberCard
                    memberImage={NazimImage}
                    memberName="Nazim Baghirov"
                    memberPosition="Head of Business Development"
                /> */}

                <MemberCard
                    memberImage={EvonneImage}
                    memberName="Evonne Chen"
                    memberPosition="Digital Marketing Manager"
                />

                <MemberCard
                    memberImage={MangusImage}
                    memberName="Magnus Allan"
                    memberPosition="Communications Manager"
                />

                {/* <MemberCard
                    memberImage={HabibImage}
                    memberName="Habib Huseynzade"
                    memberPosition="Independent Director, Oil & Gas"
                /> */}
            </div>
        </>
    )
}
