import React from "react"
import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"

import { ReactComponent as HowItWorksIllustration } from "../../../images/services-cm-illustration.svg"

export default function AssetTokenization() {
    return (
        <div className="grid xl:grid-cols-2 bg-services-at bg-no-repeat bg-cover bg-center xl:px-24 items-center justify-items-center pt-12 pb-0 xl:pb-12">
            <div className="flex flex-col items-center xl:items-start xl2:px-56 bg-at-diamond bg-no-repeat bg-left">
                <Heading6
                    text="Our services"
                    classes="text-blue-main text-center xl:text-left uppercase my-4"
                />
                <Heading2
                    text="Crypto Mining"
                    classes="text-center xl:text-left text-xl lg:text-xl"
                />

                <Paragraph
                    text="PermianChain provides cryptocurrency mining management solutions for a variety of audiences. We make it simple to do it yourself or to take a stake in industrial grade data centres alongside prominent institutional investors."
                    classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                />

                <Paragraph
                    text="The economies of scale that we generate, coupled with our relationships and know-how, mean that we can host cryptocurrency mining servers onsite at oil and gas projects on behalf of miners. Powering the servers with field-generated electricity means miners can generate cryptocurrencies at a low cost"
                    classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                />
            </div>

            <HowItWorksIllustration className="w-11/12" />
        </div>
    )
}
