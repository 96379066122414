import React from "react"

import { NavLink } from "react-router-dom"
import { Menu } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import Paragraph from "../elements/Paragraph"
// import { ReactComponent as LogoFooter } from "../../images/logo-footer.svg";
import LogoFooter2 from "../../images/Logo-T.png"

import SupplierLogo2 from "../../images/PermianSupplier.png"
import MinerLogo2 from "../../images/PermianMiner.png"
import DealerLogo2 from "../../images/PermianDealer.png"
import ExchangeLogo2 from "../../images/PermianExchange.png"
import InvestorLogo2 from "../../images/PermianInvestor.png"

import SocialIcons from "../SocialIcons"

export default function Footer() {
    return (
        <>
            <div className="mt-12 pt-32 md:px-24 xl:px-36 grid grid-cols-1 gap-y-24 md:grid-cols-6 justify-items-center justify-between border-t border-dashed border-blue-borderColor">
                <div className="space-y-4 md:col-span-3 flex flex-col items-center xl:items-start md:mr-8 md:border-r md:border-dashed md:border-blue-borderColor">
                    <img
                        src={LogoFooter2}
                        alt="Company Logo"
                        className="w-2/4"
                    />
                    <Paragraph
                        text="PermianChain Technologies is a Toronto-based technology company that is focused on bringing efficiencies of the blockchain to natural resources. Creating value in resource finance and sustainability."
                        classes="text-md text-left opacity-50 px-4 md:pr-8"
                    />
                </div>
                <div className="flex flex-col space-y-8 items-start">
                    <ul className="flex space-y-5 flex-col items-start text-md font-medium text-blue-semilight">
                        <li>
                            <NavLink to="/" activeClassName="text-blue-main">
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/about"
                                activeClassName="text-blue-main"
                            >
                                About
                            </NavLink>
                        </li>
                        <Menu as="li" className="relative">
                            <Menu.Button className="inline-flex justify-center w-full font-medium text-blue-semilight focus:outline-none">
                                Products
                                <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5 mt-2"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                            <Menu.Items className="origin-top-left absolute bottom-7 inset-x-0 mt-2 w-56 flex flex-col items-center bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-lightGray focus:outline-none">
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-supplier"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                        >
                                            {/* <SupplierLogo className="w-9/12 mx-auto " /> */}
                                            <img
                                                src={SupplierLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-miner"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                        >
                                            {/* <MinerLogo className="w-9/12 mx-auto" /> */}
                                            <img
                                                src={MinerLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-dealer"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                        >
                                            {/* <DealerLogo className="w-10/12 mx-auto" /> */}
                                            <img
                                                src={DealerLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-exchange"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                        >
                                            {/* <ExchangeLogo className="w-9/12 mx-auto" /> */}
                                            <img
                                                src={ExchangeLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/products-investor"
                                            className={`px-4 py-4 uppercase opacity-50 hover:opacity-100`}
                                            activeClassName="opacity-100 "
                                        >
                                            {/* <InvestorLogo className="w-9/12 mx-auto" /> */}
                                            <img
                                                src={InvestorLogo2}
                                                alt="product logo"
                                                className="w-10/12 mx-auto "
                                            />
                                        </NavLink>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                        <li>
                            <NavLink
                                to="/services"
                                activeClassName="text-blue-main"
                            >
                                Services
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div className="flex flex-col space-y-8 items-start">
                    <ul className="flex space-y-5 flex-col items-start text-md font-medium text-blue-semilight">
                        <li>
                            <NavLink
                                to="/contact"
                                activeClassName="text-blue-main"
                            >
                                Contact
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/blog"
                                activeClassName="text-blue-main"
                            >
                                Blog
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                to="/resources"
                                activeClassName="text-blue-main"
                            >
                                Resources
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                to="/terms-of-use"
                                activeClassName="text-blue-main"
                            >
                                Terms of Use
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/privacy-policy"
                                activeClassName="text-blue-main"
                            >
                                Privacy Policy
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div className="flex flex-col space-y-8 items-start">
                    <div className="flex flex-col space-y-4">
                        <SocialIcons color="semilight" />
                    </div>
                </div>
            </div>
            <p className="pt-24 mb-3 text-center text-sm opacity-50">
                ©Copyright 2021 PermianChain – All Rights Reserved
            </p>
        </>
    )
}
