import React from "react"
import { Link } from "../elements/Button"

import { Heading2 } from "../elements/Headings"
import Paragraph from "../elements/Paragraph"

export default function CallToAction({ category, link }) {
    return (
        <div className="mt-24 p-12 xl:p-24 lg:p-0 lg:h-96 lg:rounded-large lg:mx-24 whitespace-pre-wrap filter flex flex-col items-center justify-center lg:space-y-8  bg-cta-illustration bg-clip-padding bg-cover bg-no-repeat bg-center ">
            <div className="absolute inline-flex bg-white bg-opacity-10  "></div>
            <div>
                <Heading2
                    text="Get in touch!"
                    classes=" text-center text-xl lg:text-xl3 font-heavy"
                    color="white"
                />
                <div className="w-3/4 mx-auto">
                    <Paragraph
                        text="Register your interest to explore how we create value in resource finance and sustainability"
                        classes="text-center opacity-60 py-4 whitespace-pre-wrap text-sm lg:text-sm2 text-white"
                    />
                </div>
            </div>
            <Link
                isBlank
                text="Register your interest"
                mode="secondary"
                classes=" mx-auto text-white bg-white bg-opacity-30"
                href={
                    link
                        ? link
                        : `https://${category || "www"}.permianchain.com/signup`
                }
            />
        </div>
    )
}
