import React from "react"
import { Heading6 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"

export default function ApproachCard({ icon, title, text }) {
    return (
        <div className="flex flex-col items-center md:items-start px-12">
            <img src={icon} alt="approach icon" />
            <Heading6
                text={title}
                classes="text-blue-main text-center uppercase my-4"
            />
            <Paragraph
                text={text}
                classes="opacity-80 text-center md:text-left leading-normal"
            />
        </div>
    )
}
