import React from "react"

// Elements
import { Heading1 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"
import { Link } from "../../elements/Button"

import ServicesIllustration from "../../../images/services-illustration.png"
import SocialIcons from "../../SocialIcons"

function Banner() {
    return (
        <div className=" bg-blue-light xl:px-14 xl2:px-4 lg:pt-28 lg:pb-14">
            <div className="pt-40 lg:pt-20 pb-12 xl2:mx-28 xl:px-12  bg-blue-lightblue xl:rounded-t-xl xl:rounded-b-large border border-blue-borderColor border-opacity-50 bg-opacity-50">
                <div className="flex flex-col space-y-10 justify-between items-center xl:flex-row xl:space-x-2 xl2:space-x-10">
                    <div className="flex flex-col items-center xl:items-start xl:w-2/6 xl2:ml-12 xl2:w-2/6">
                        <Heading1
                            text="We Create New Strategies"
                            classes="text-xl2 text-center xl:text-left xl2:text-xl3"
                        />
                        <Paragraph
                            text="PermianChain creates new strategies for the energy sector, digital asset miners, investors and societies
              "
                            classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left xl:text-sm"
                        />

                        <div className="flex space-x-2 mt-10">
                            <Link
                                isBlank
                                text="Corporate Profile"
                                classes="bg-blue-extralight"
                                mode="secondary"
                                href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            />
                        </div>
                    </div>

                    <div className="">
                        <img src={ServicesIllustration} alt="about"></img>
                    </div>

                    <div className="flex flex-row items-center justify-center space-x-8 xl:flex-col xl:space-x-0 xl:space-y-8 xl:pl-4 xl2:px-20">
                        <SocialIcons color="main" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
