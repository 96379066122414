import React from "react"

import { Heading2, Heading6 } from "../../elements/Headings"
import WorkingWithUsCard from "./WorkingWithUsCard"

export default function WorkingWithUs() {
    const benefits = [
        {
            id: "01",
            heading: "Reduced administrative costs",
        },
        {
            id: "02",
            heading: "Reduced reliance on brokers",
        },
        { id: "03", heading: "attracting new liquidity" },
        {
            id: "04",
            heading: "A just-in-time system for oil and gas",
        },
        {
            id: "05",
            heading: "a better understanding of the pressure points",
        },
        { id: "06", heading: "Less wastage" },
        { id: "07", heading: "Less opportunity for graft" },
        {
            id: "08",
            heading: "add value earlier in the process",
        },
        { id: "00" },
        {
            id: "09",
            heading: "the right time to make changes",
        },
        {
            id: "10",
            heading: "enhanced efficientcy, less administration",
        },
    ]

    return (
        <div>
            <div className="lg:px-14 whitespace-pre-wrap flex flex-col items-center space-y-20 pt-24">
                <div className="flex flex-col">
                    <Heading6
                        text="The benefits of working with us"
                        classes="text-blue-main text-center uppercase my-4 "
                    />
                    <Heading2
                        text="Making it easier and more efficient to invest in oil and gas"
                        classes="text-center text-xl"
                    />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl1:grid-cols-4 divide-y lg:divide-y-0 lg:divide-x divide-blue-lightblue gap-x-12 gap-y-1 lg:gap-y-20 items-center justify-center ">
                    {benefits.map(benefit => (
                        <WorkingWithUsCard
                            key={benefit.id}
                            number={benefit.id}
                            heading={benefit.heading}
                        />
                    ))}
                    <div></div>
                </div>
            </div>
        </div>
    )
}
