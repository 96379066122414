import React from "react"

import ExchangeLogo2 from "../../../../images/PermianExchange.png"

import Button from "../../../elements/Button"
import { Heading2 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"
import ProductFeature from "./ProductFeature"

import MarketIcon from "../../../../images/icons/market.png"
import MoneyUpIcon from "../../../../images/icons/moneyup.png"
import MoneyBagsIcon from "../../../../images/icons/moneybags.png"

function Exchange({ illustration }) {
    return (
        <>
            <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center items-center pt-12 pb-14 pl-0 xl:pl-20">
                <div className="flex flex-col space-y-8 ">
                    <img
                        src={ExchangeLogo2}
                        alt="Miner Logo"
                        className="mx-auto lg:mx-0 w-2/6 mb-5"
                    />
                    <Heading2
                        text="Facilitating trades of Natural Asset Security Tokens (NASTs)"
                        classes="text-md3 text-center lg:text-left"
                    />

                    <Paragraph
                        classes="opacity-60 text-center lg:text-left text-sm2"
                        text="PermianChain Exchange creates a regulated environment for registered market dealers to execute buy and sell mandates of NASTs issued by E&P companies. Learn more about NAST issuances by reading our case study."
                    />

                    <Button
                        classes="mx-auto lg:mx-0 w-52 text-center"
                        text="Coming Soon"
                        mode="primary"
                    />
                </div>
                <img src={illustration} className="w-4/5" alt="illustration" />
            </div>
            <div className="flex flex-col xl:flex-row pl-0 xl:pl-20 pb-14 space-x-0 xl:space-x-14 space-y-14 xl:space-y-0 ">
                <ProductFeature
                    icon={MarketIcon}
                    headingText="Compliant marketplace for exempt securities"
                    pText="PermianChain Exchange provides a compliant digital securities marketplace that allows for direct regulatory oversight by integrating enterprise blockchain solutions to establish transparency and trust, using Hyperledger Fabric."
                />
                <ProductFeature
                    icon={MoneyUpIcon}
                    headingText="Streamlining secondary exempt security transactions"
                    pText="PermianChain Exchange enables shareholders of privately held E&P companies to submit 'buy' and 'sell' mandates to their registered market dealers instantly, eliminating the administrative and convoluted processes involved in buying, selling and transferring of secondary exempt securities."
                />
                <ProductFeature
                    icon={MoneyBagsIcon}
                    headingText="Unlocking liquidity in natural resource companies"
                    pText="PermianChain Exchange works closely with issuers and registered market dealers to create a robust secondary private equity market for alternative investments that unlock untapped value from natural resource companies, with a focus on the energy sector."
                />
            </div>
        </>
    )
}

export default Exchange
