import React from "react"
import { Heading6 } from "../../../elements/Headings"
import Paragraph from "../../../elements/Paragraph"

export default function ProductFeature({ icon, headingText, pText }) {
    return (
        <div className="flex flex-col items-center lg:items-start space-y-4 border-l border-blueGray-300 pl-4">
            <img src={icon} alt="product-feature-icon" />
            <Heading6
                text={headingText}
                classes="text-sm opacity-70 text-center lg:text-left"
            />
            <Paragraph
                text={pText}
                classes="opacity-60 text-sm text-center lg:text-left"
            />
        </div>
    )
}
