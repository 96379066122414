import React from "react"
// import Calculator from "../../../calculator/Calculator";

import { Heading2, Heading6 } from "../../elements/Headings"
import Paragraph from "../../elements/Paragraph"
import CalculatorForm from "./calculator/CalculatorForm"

export default function CalculatorSection() {
    return (
        // REMOVE h-84
        <div className="my-32 py-24 xl:mr-28 whitespace-pre-wrap flex flex-col space-y-24 items-center xl:rounded-tr-xl bg-calculator-illustration bg-center bg-no-repeat bg-cover ">
            <div>
                <Heading6
                    text="Our calculator"
                    classes="text-blue-main text-center uppercase my-4"
                />
                <Heading2
                    text="Bitcoin Mining Profit Calculator"
                    classes="text-center text-xl lg:text-xl2"
                />
                <div className="w-3/4 mx-auto">
                    <Paragraph
                        text=""
                        classes="text-center opacity-60 pt-4 whitespace-pre-wrap text-sm2 lg:text-md"
                    />
                </div>
            </div>

            <CalculatorForm />
        </div>
    )
}

/* <select class="currency-selector">
  <option data-symbol="$" data-placeholder="0.00" selected>
    USD
  </option>
  <option data-symbol="€" data-placeholder="0.00">
    EUR
  </option>
  <option data-symbol="£" data-placeholder="0.00">
    GBP
  </option>
  <option data-symbol="¥" data-placeholder="0">
    JPY
  </option>
  <option data-symbol="$" data-placeholder="0.00">
    CAD
  </option>
  <option data-symbol="$" data-placeholder="0.00">
    AUD
  </option>
</select>; */
