import React, { useState } from "react"
import CookieConsent from "react-cookie-consent"

export default function Consent() {
    const [checked, setChecked] = useState(true)
    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText="Got it"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#f5f5f5", color: "#444" }}
                buttonStyle={{
                    color: "#4e503b",
                    fontSize: "13px",
                    marginTop: "10px",
                }}
                expires={150}
                onAccept={() => {
                    if (!checked) {
                        window["ga-disable-G-8J160JJLLD"] = true
                    }
                }}
            >
                <div className="text-sm pl-2 text-justify">
                    <p>
                        We use cookies to improve and customize our services,
                        ensure compliance, and protect your account. By
                        continuing to use PermianChain, you agree to our use of
                        cookies. To learn more about cookies and how to change
                        your settings, view our{" "}
                        <a
                            className="text-red-400 underline"
                            href="/privacy-policy"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                    <label class="flex mt-2">
                        <input
                            checked
                            disabled
                            type="checkbox"
                            class="mt-1 black"
                            style={{ color: "#aaa" }}
                        />
                        <span class="ml-2">
                            Necessary Cookies (required): necessary cookies
                            enable core functionality such as security, network
                            management, and accessibility.
                        </span>
                    </label>

                    <label class="flex">
                        <input
                            onChange={() => setChecked(!checked)}
                            checked={checked}
                            type="checkbox"
                            class="mt-1"
                        />
                        <span class="ml-2">
                            Analytics Cookies: we use analytics cookies to help
                            us improve our services by collecting and reporting
                            information on how you use our site, and to measure
                            the effectiveness of our advertising campaigns.
                        </span>
                    </label>
                </div>
            </CookieConsent>
        </>
    )
}
