import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
// eslint-disable-next-line
import { BrowserRouter, HashRouter } from "react-router-dom"

import ScrollToTop from "./ScrollToTop"

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop />
        <App />
    </BrowserRouter>,
    document.getElementById("root"),
)
