import React from "react"

import { Heading1 } from "../components/elements/Headings"
import Paragraph from "../components/elements/Paragraph"
import Footer from "../components/layout/Footer"
import { Helmet } from "react-helmet"

function Resources() {
    return (
        <>
            <Helmet>
                <title>
                    Natural Resources Tokenization Platform - PermianChain
                    Technologies Inc.
                </title>
                <meta
                    name="title"
                    property="og:title"
                    content="Natural Resources Tokenization Platform - PermianChain Technologies
                    Inc."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://permianchain.com/terms-of-use"
                />
                <link
                    rel="canonical"
                    href="https://permianchain.com/terms-of-use"
                />
            </Helmet>
            <div className="flex flex-col">
                <div className="grid xl:grid-cols-1 gap-y-12 bg-services-at bg-no-repeat bg-center bg-cover items-center justify-items-center mt-12 py-36">
                    <div className="flex flex-col items-center xl:items-start bg-at-diamond bg-no-repeat bg-left ml-0 xl2:ml-40 px-0 sm:px-36">
                        <Heading1
                            text="Resources"
                            classes="text-center sm:text-left text-xl sm:text-xl"
                        />
                        <Paragraph
                            text="content..."
                            classes="mt-5 text-xs2 leading-6 text-center font-medium text-blueGray-500 xl:text-left sm:text-sm"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Resources
