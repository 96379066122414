import React from "react"
import { Heading2, Heading6 } from "../../elements/Headings"

// import { ReactComponent as CryptoMiningIcon } from "../../../images/icons/wwd-about/crypto-mining-icon.svg";
// import { ReactComponent as InvestorsIcon } from "../../../images/icons/wwd-about/investors-icon.svg";
// import { ReactComponent as OilIcon } from "../../../images/icons/wwd-about/oil-icon.svg";
// import { ReactComponent as RegulatorsIcon } from "../../../images/icons/wwd-about/regulators-icon.svg";

import WhatWeDoCard from "./wwd/WhatWeDoCard"

import OilGas from "../../../images/oil-gas.png"
import OilGasMobile from "../../../images/oil-gas-mobile.png"
import CryptoMining from "../../../images/crypto-mining.png"
import Investors from "../../../images/investors.png"
import Regulators from "../../../images/regulators.png"

export default function WhatWeDo() {
    // const content = [
    //   {
    //     icon: { OilIcon },
    //     title: "Oil & Gas Firms",
    //     texts: [
    //       "Enhanced Back Office",
    //       "New Revenue stream from cryptocurrency miners",
    //       "Isolate and manage project delays",
    //     ],
    //   },
    //   {
    //     icon: { CryptoMiningIcon },
    //     title: "Crypto Miners",
    //     texts: [
    //       "Cheaper Energy costs",
    //       "Choice of Sites",
    //       "Simple Mangement Process",
    //       "Consistent revenue stream",
    //     ],
    //   },
    //   {
    //     icon: { InvestorsIcon },
    //     title: "Investors",
    //     texts: [
    //       "Reduced barriers to entry",
    //       "Simple Investment management",
    //       "Enhanced project transparency",
    //     ],
    //   },
    //   {
    //     icon: { RegulatorsIcon },
    //     title: "Regulators",
    //     texts: [
    //       "Clear understanding of projects",
    //       "Low cost of reconcilation",
    //       "Enhanced transparency",
    //     ],
    //   },
    // ];

    return (
        <div className="mt-24 py-32 lg:rounded-sm bg-about-wwd bg-cover w-100 bg-no-repeat bg-center flex flex-col space-y-24">
            <div className="">
                <Heading6
                    text="what we do"
                    classes="text-blue-main text-center uppercase my-4"
                />
                <Heading2
                    text="Harness the blockchain to augment investment and trading in the oil and gas sector through PermianChain"
                    classes=" mx-auto text-center text-xl lg:text-xl xl:w-2/6 leading-normal "
                />
            </div>

            <div className="grid grid-cols-2 gap-y-20 xl:gap-x-8 xl:px-32 justify-items-center ">
                <WhatWeDoCard
                    className="col-span-2"
                    cardImage={OilGas}
                    mobileCardImage={OilGasMobile}
                />
                <WhatWeDoCard
                    className="col-span-2 xl:col-span-1"
                    cardImage={CryptoMining}
                />
                <WhatWeDoCard
                    className="col-span-2 xl:col-span-1"
                    cardImage={Investors}
                />
                <WhatWeDoCard className="col-span-2" cardImage={Regulators} />
            </div>
        </div>
    )
}
